<div class="modal fade" [attr.id]="modalId" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" [attr.data-backdrop]="'static'">
	<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<ng-content class="modal-title" id="exampleModalLabel" select="[head]"></ng-content>
				<button type="button" class="close mr-1" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<ng-content select="[body]"></ng-content>
			</div>
			<div class="modal-footer" [ngClass]="showModalFooter?'d-flex justify-content-between':'d-none'">
				<button type="button" class="btn cancel-btn btn-secondary-color align-self-center ml-3 mb-3" data-dismiss="modal" *ngIf="cancelFooter">
					<ng-content select="[footer]"></ng-content>
				</button>
				<button type="button" class="btn custom-btn btn-primary-color align-self-center mr-3 mb-3" *ngIf="submitFooter">
					<ng-content select="[footer1]"></ng-content>
				</button>
			</div>
		</div>
	</div>
</div>
