import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'truncateText',
	pure: false,
})
export class TruncateTextPipe implements PipeTransform {
	transform(values: string, strlen: number): any {
		let value = '';
		if (values !== null && values !== undefined) {
			value = values.toString();
			if (value.length <= strlen) {
				return value + '\n';
			}
			return value.substring(0, strlen) + '...';
		}
		return value;
	}
}
