import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'Textbold',
})
export class TextBoldPipe implements PipeTransform {
    constructor() {}

    transform(value: string): any {
        return this.replace(value, '^.*?(?=:)');
    }

    replace(str: string, regex: string) {
        return str.replace(new RegExp(`(${regex})`, 'gi'), '<b>$1</b>');
    }
}
