import { AbstractControl, ValidationErrors, ValidatorFn, FormGroup } from '@angular/forms';

export class CustomValidator {
    static CustomPatternValidator(regex: RegExp, error: ValidationErrors | null): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                // if control is empty return no error
                return null;
            }
            // test regex expression with passed value
            const valid = regex.test(control.value);
            // if true, else no error OR pass second params
            return valid ? null : error;
        };
    }

    static MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({
                    mustMatch: true,
                });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }
}
