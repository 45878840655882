/** Deault packages */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
/** Component */
import { UpdatePasswordComponent } from './update-password.component';

const updatePwdRoutes: Routes = [
	{
		path: 'xyz',
		component: UpdatePasswordComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(updatePwdRoutes)],
	exports: [RouterModule],
})
export class UpdatePasswordRoutingModule {}
