import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROLES } from '@auth/model/roles.enum';
import { AuthService } from '@auth/services/auth.service';
import { AUTHORIZATION_SESSION_KEY, CoreConstants } from '@core/core.constants';
import { AESEncryptDecryptService } from '@core/storage/storage';

@Component({
    selector: 'app-preview-user',
    templateUrl: './preview-user.component.html',
    styleUrls: ['./preview-user.component.scss'],
})
export class PreviewUserComponent implements OnInit, OnChanges {
    createUserToken: string = '';

    constructor(private activeRoute: ActivatedRoute, private authService: AuthService, private router: Router, private crypto: AESEncryptDecryptService) {
        this.activeRoute.queryParamMap.subscribe((params: any) => {
            this.createUserToken = params.get('token');
            sessionStorage.setItem(AUTHORIZATION_SESSION_KEY, this.createUserToken);
            AuthService.setAuthorizationToken(params.get('token'));
            AuthService.setUserAuthDetails({
                authorizationToken: params.get('token'),
                role: params.get('role') as ROLES,
                viewMultiDealScreen: JSON.parse(params.get('viewMultiDealScreen')),
                multiDealScreenCapabilities: params.get('multiDealScreenCapabilities') != null ? this.crypto.decrypt(params.get('multiDealScreenCapabilities')).split(',') : params.get('multiDealScreenCapabilities'),
            });
            this.authService.isAuthenticationCompleted$.next(JSON.parse(params.get('isValid')));
            this.authService.isPreviewUser.next(JSON.parse((params.get('isValid'))));
		    sessionStorage.setItem('PreviewUser', params.get('PreviewUser'));
			sessionStorage.setItem('previewUserInfo', this.crypto.encrypt(JSON.stringify(params.get('status'))));
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.router.navigateByUrl(CoreConstants.dashboardPath);
        }, 500);
    }

    ngOnChanges(): void {}
}
