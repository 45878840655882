import { Injectable, OnDestroy, Inject } from '@angular/core';
import { USER_ROLES } from '../model/auth.model';
import bcrypt from 'bcryptjs';
import { authDetails, userAuthDetails } from '../model/authDetails.modal';
import { Router } from '@angular/router';
import { PERMISSIONS } from '../model/permissions.enum';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { RoleService } from './role.service';
import { environment } from 'src/environments/environment';
import { AUTHORIZATION_DETAILS, AUTHORIZATION_SESSION_KEY } from '../constants/auth.constants';
import { sessionError } from '../model/sessionError.type';
import { CoreConstants, OKTA } from 'src/app/core/core.constants';
import { AESEncryptDecryptService } from 'src/app/core/storage/storage';
import { tap } from 'rxjs/operators';
import { ROLES } from '@auth/model/roles.enum';
import { OktaAuth, AuthState, UserClaims } from '@okta/okta-auth-js';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { map, filter } from 'rxjs/operators';
import { JwtTokenResponse } from '@auth/auth.model';
import { HttpErrorService } from '@layouts/error/service/HttpError.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService implements OnDestroy {
	public static USER_ROLES = USER_ROLES;
	public static sessionErrorNotification$ = new Subject<sessionError>();
	public isAuthenticationCompleted$ = new BehaviorSubject<boolean>(sessionStorage.getItem(AUTHORIZATION_SESSION_KEY) != null ? true : false); // {1}
	private userName$ = new BehaviorSubject<string>('');
	// Will store last route denied by auth gaurd to fix redirect issue
	public authGaurdFailedRoute: string = '/';
	private static _AUTHORIZATION_TOKEN: string = '';
	private _authDetails: authDetails | null = null;
	private static _userAuthDetails: userAuthDetails | null = null;
	public AUTHENTICATION_TOKEN = '';
	private subscriptions = new Subscription();
	isAuthenticated: Observable<boolean> | any = false;
	clearTime: any;
	public isPreviewUser = new BehaviorSubject<boolean>(sessionStorage.getItem('previewInfo') != null ? true : false);
	constructor(
		private router: Router,
		private roleService: RoleService,
		private crypto: AESEncryptDecryptService,
		@Inject(OKTA_AUTH) private oktaService: OktaAuth,
		private oktaStateService: OktaAuthStateService,
		private httpErrorService: HttpErrorService,
	) {
		this.setAuthenticationEventSubscription();
        this.setAuthorizationTokenGenerationSubscription();
        if (OKTA.oktaInternalUrls.includes(window.location.origin) && environment.isOKTAEnabled) {
            if (!this.isAuthenticationCompleted$.value) {
                this.initOktaLogin();
            }
            this.oktaService.authStateManager.subscribe(async (authState: any) => {
                if (!authState.isAuthenticated) {
                    return;
                }
                await this.oktaService.tokenManager.setTokens(authState);
                this.isAuthenticated = await authState.isAuthenticated;
            });
            this.oktaService.authStateManager.updateAuthState();
            this.isAuthenticated = this.oktaStateService.authState$.pipe(
                filter((s: AuthState) => !!s),
                map((s: AuthState) => s.isAuthenticated ?? false),
            );
        }
	}

	private async handleOktaRedirect(): Promise<void> {
		try {
			const isUserLoggedIn = await this.oktaService.isAuthenticated();
			if (!isUserLoggedIn) await this.oktaService.handleLoginRedirect();
			const token: string = (await this.oktaService.getAccessToken()) as string;
			const userInfo: UserClaims | any = await this.oktaService.getUser();
			AuthService.setUserName(userInfo?.name);
			this.AUTHENTICATION_TOKEN = token || '';
			sessionStorage.setItem('token', token);
			await this.getCustomJWToken();
		} catch (e: unknown) {
			this.httpErrorService.addError({
				code: 401,
				message: CoreConstants.oktaAccessDenied,
			});
			localStorage.clear();
			sessionStorage.clear();
			// this.toasterService.toastFailure(CoreConstants.oktaAccessDenied, 300000, 'toast-right');
			this.router.navigate(['error']);
		}
	}

	public async initOktaLogin(): Promise<void> {
		if (this.oktaService.token.isLoginRedirect()) {
			this.handleOktaRedirect();
			return;
		} else if (!(await this.oktaService.isAuthenticated())) {
			try {
				await this.oktaService.signInWithRedirect({ originalUri: '/dashboard' });
				await this.router.navigateByUrl(CoreConstants.dashboardPath);
				this.isAuthenticationCompleted$.next(false);
				this.removeAuthDetailsIfExist();
			} catch (e) {}
		} else {
			// User is authenticated
			try {
				const token: string = this.oktaService.getAccessToken() as string;
				const userInfo: UserClaims | any = await this.oktaService.getUser();
				AuthService.setUserName(userInfo?.name);
				this.AUTHENTICATION_TOKEN = token || '';
				sessionStorage.setItem('token', token);
				await this.getCustomJWToken();
				// await this.router.navigateByUrl(CoreConstants.dashboardPath);
				return;
			} catch (e) {}
		}
	}

	getCustomJWToken(): void {
		clearTimeout(this.clearTime);
		this.roleService.getOktaJwtAccessToken().subscribe({
			next: (tokenResponse: JwtTokenResponse) => {
				AuthService.AUTHORIZATION_TOKEN = tokenResponse.token ?? '';
				AuthService.setUserAuthDetails({
					role: tokenResponse.role as ROLES,
				});
				this.clearTime = setTimeout(() => {
					this.isAuthenticationCompleted$.next(true);
					this.router.navigateByUrl(CoreConstants.dashboardPath);
				});
			},
			error: () => {
				localStorage.clear();
				sessionStorage.clear();
				this.isAuthenticationCompleted$.next(false);
				this.router.navigate(['/']);
				// this.logout();
			},
		});
	}

	setUserName(userName: string) {
		this.userName$.next(userName);
	}

	getUserName() {
		return this.userName$;
	}

	public async logout(): Promise<void> {
		try {
			await this.oktaService.signOut();
			AuthService.clearSessionStorage();
			this.isAuthenticationCompleted$.next(false);
			await this.router.navigate(['/']);
		} catch (e) {}
	}

	public get permissionList(): string[] {
		return AuthService._userAuthDetails?.permissions || [];
	}
	// public get name(): string {
	//   return this._authDetails?.msalAccountDetails?.name || '-';
	// }
	public get role(): string {
		return this._authDetails?.role || '';
	}
	public get isCreditTeamRole(): boolean {
		return this.role.toLowerCase().includes('credit');
	}
	// External Login methods
	public static getAuthorizationToken(): string {
		return sessionStorage.getItem(AUTHORIZATION_SESSION_KEY) || '';
	}
	public static setAuthorizationToken(accessToken: string) {
		return sessionStorage.setItem(AUTHORIZATION_SESSION_KEY, accessToken);
	}
	public static clearSessionStorage() {
		return sessionStorage.clear();
	}
	public static getUserName() {
		return localStorage.getItem('userName');
	}
	public static setUserName(userName: string) {
		localStorage.setItem('userName', userName);
	}

	public static setUserAuthDetails(userAuthDetails: userAuthDetails) {
		sessionStorage.setItem(AUTHORIZATION_DETAILS, userAuthDetails ? JSON.stringify(userAuthDetails) : '');
		this._userAuthDetails = userAuthDetails;
	}
	userLoginDetails() {
		let payload = localStorage.getItem(CoreConstants.userDetails) || '';
		if (localStorage.getItem(CoreConstants.userDetails)) {
			payload = this.crypto.decrypt(payload);
		}
		return payload ? JSON.parse(payload) : null;
	}
	//External Login Methods
	public get authorizationToken(): string {
		return this._authDetails?.authorizationToken || '';
	}
	public static get AuthorizationToken(): string {
		return AuthService._AUTHORIZATION_TOKEN;
	}
	private static set AUTHORIZATION_TOKEN(token: string) {
		AuthService._AUTHORIZATION_TOKEN = token || '';
	}
	// This function will return boolean result based on screen entitlement
	public havePermission(permissions: PERMISSIONS[]): boolean {
		const usersPermissions = AuthService._userAuthDetails?.permissions || [];
		return permissions.some(permission => usersPermissions.includes(permission as PERMISSIONS));
	}

	// This function will return boolean result based on role
	public haveRole(roles: ROLES[]): boolean {
		if (AuthService._userAuthDetails === null) {
			AuthService._userAuthDetails = this.roleService.getAuthorizationPayload();
		}
		const userRole = AuthService._userAuthDetails?.role;
		return roles.includes(userRole as ROLES);
	}

	private setAuthorizationTokenGenerationSubscription(): void {
		this.subscriptions.add(
			this.roleService.authorizationTokenGenerated$.subscribe(authDetails => {
				if (!authDetails) return;
				AuthService.AUTHORIZATION_TOKEN = authDetails.authorizationToken || '';
			}),
		);
	}
	private async setAuthenticationEventSubscription(): Promise<void> {}

	setUserRole(user_role: keyof typeof USER_ROLES): void {
		const salt = bcrypt.genSaltSync(10);
		const hashRole = bcrypt.hashSync([USER_ROLES[user_role]].toString(), salt);
		localStorage.setItem('userRole', hashRole);
	}
	getUserRole(): any {
		return localStorage.getItem('userRole');
	}

	getDealAccessToken(dealId: string): Observable<userAuthDetails> {
		return this.roleService.getDealBasedAccessToken(dealId).pipe(
			tap(tokenModel => {
				AuthService.AUTHORIZATION_TOKEN = tokenModel.authorizationToken ?? '';
				AuthService._userAuthDetails = tokenModel;
			}),
		);
	}

	private removeAuthDetailsIfExist() {
		sessionStorage.removeItem(AUTHORIZATION_SESSION_KEY);
		AuthService.AUTHORIZATION_TOKEN = '';
	}

	public ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		clearTimeout(this.clearTime);
	}
}
