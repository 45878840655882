<header class="header-style">
    <div class="row">
        <div class="col">
            <img class="bank-image" [src]="WABLogo" alt="header-logo" DisableRightClick>
        </div>
    </div>
</header>
<div class="main-content">
    <div class="container pr-0">
        <div class="row align-items-center vh-98">
            <div class="col-md-7 col-lg-6 col-sm-12 mx-auto pt-7">
                <div class="text-center px-5 pt-7 pb-2">
                </div>
                <div class="card main-card rounded-0 mt-5">
                    <div class="card-body">
                        <div class="two-step">
                            <ng-content id="ContentId" select="[passwordContent]"></ng-content>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 col-lg-6 col-sm-12 mx-auto order-first order-md-1">
                <div class="flex-grow justify-content-start bd-highlight">
                    <div class="flex-fill bd-highlight fs-30px lh-35px text-navy">Welcome to </div>
                    <div class="flex-fill bd-highlight fs-45px lh-53px text-navy">Gateway Client </div>
                    <div class="flex-fill bd-highlight fs-30px lh-35px text-navy">Sign in to manage your deals</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer-style">
    <div class="row">
        <div class="col text-right">
            <div class="row">
                <div class="col mr-5">
                    <p class="text">{{WABTitle}}</p>
                </div>
            </div>
        </div>
    </div>
</div>