import { Directive, ElementRef, Input, OnInit, OnChanges } from '@angular/core';

@Directive({
    selector: '[fontFamilyDirective]',
})
export class FontFamilyChangeDirective implements OnInit, OnChanges {
    @Input() fontFamilyDirective: string | any = '';

    constructor(private el: ElementRef) {}

    ngOnInit() {
        if (this.fontFamilyDirective !== null && this.fontFamilyDirective !== undefined) {
            const data: string = this.fontFamilyDirective?.match(/\d.+/)[0];
            if (new String(data).length > 0) {
                this.el.nativeElement.classList.add('RobotoRegular');
            } else {
                this.el.nativeElement.classList.add('');
            }
        }
    }

    ngOnChanges() {
        // if (this.fontFamilyDirective !== null) {
		// 	let digitData: any = '';
		// 	let wordData: any = '';
        //     // const data: string = this.fontFamilyDirective?.match(/\d.+/)[0];
        //     digitData = this.fontFamilyDirective?.match(/(-\d+|\d+)(,\d+)*(\.\d+)*/g);
		// 	wordData = this.fontFamilyDirective?.match(/(\D+|\D+)(,\D+)*(\.\D+)*/g);
        //     if (digitData !== null) {
		// 		const elRef = this.el.nativeElement;
		// 		this.renderer.setProperty(elRef, 'innerHTML', `<span>${wordData}</span><span class="RobotoRegular">${digitData}</span>`);
        //         // this.el.nativeElement.classList.add('RobotoRegular');
        //     } else {
        //         const elRef = this.el.nativeElement;
		// 		this.renderer.setProperty(elRef, 'innerHTML', `<span>${this.fontFamilyDirective}</span>`);
        //     }
        // }
    }
}
