import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'emailTruncate',
	pure: true,
})
export class EmailTruncatePipe implements PipeTransform {
	transform(values: string): any {
		if (values !== null) {
			const value = values.toString().split('@');
			return value[0].slice(-2) + '@' + value[1];
		}
	}
}
