// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { OKTA } from '../app/core/core.constants';

export const environment = {
	production: false,
	name: 'qa',
	msalConfig: {
		clientId: '23eebb5f-a09e-48dc-ac7d-9d89f69ba312',
		authority: 'https://login.microsoftonline.com/50c56ba2-8fca-42f4-8307-025e0d821d34',
		redirectUri: 'https://gateway-client-qa.westernalliancebank.com/login/internal',
	},
	msalProtectedRoutes: [{ url: 'https://bes-azw-qa.westernalliancebancorp.com/api/user/getjwttoken', scope: ['api://94712410-f7e1-4fd0-b5e7-436f29ce5b9e/api-access'] }],
	silentTokenScope: ['api://94712410-f7e1-4fd0-b5e7-436f29ce5b9e/api-access'],
	appInsightsInstrumentationKey: '351807f7-987e-448b-8b95-4effb94b798c',
	enableWebLogs: true,
	isOKTAEnabled: false,
	oktaConfig: {
		clientId: '0oa3iwkymaS5eXPjo697',
		issuer: 'https://wab.okta.com/oauth2/aus3iwkqu9UnKb0FB697',
		redirectUri: `${window.location.origin}${OKTA.redirectPath}`,
		scopes: ['openid', 'profile', 'email'],
		pkce: true,
	},
};
