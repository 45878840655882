/** Default packages */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

/** Shared modules */
import { MaterialModule } from '@shared/modules/material.module';
import { PipesModule } from '@shared/modules/pipes.module';
import { PasswordCriteriaModule } from '@shared/modules/shared/password-criteria.module';
/** Third party package */
import { NgOtpInputModule } from 'ng-otp-input';
/** Routing */
import { UpdatePasswordRoutingModule } from './update-password-routing.module';
/** Component */
import { UpdatePasswordComponent } from './update-password.component';
/** Component */
import { UpdatePasswordService } from './services/update-password.service';
import { UserAuthenticateBackgroundModule } from '@shared/modules/shared/user-authenticate-background.module';
import { LoaderModule } from '@shared/modules/shared/loader.module';
import { OTPInputModule } from '@shared/modules/shared/otp-input.module';

@NgModule({
    declarations: [UpdatePasswordComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        PipesModule,
        NgOtpInputModule,
        PasswordCriteriaModule,
        UserAuthenticateBackgroundModule,
        LoaderModule,
        OTPInputModule,
        UpdatePasswordRoutingModule,
    ],
    exports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        PipesModule,
        NgOtpInputModule,
        PasswordCriteriaModule,
        UserAuthenticateBackgroundModule,
        LoaderModule,
        OTPInputModule,
        UpdatePasswordRoutingModule,
    ],
    providers: [UpdatePasswordService],
})
export class UpdatePasswordModule {}
