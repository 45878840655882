import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[restrictSpecialChar]',
})
export class RestrictSpecialCharsDirective {
	constructor() {}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: KeyboardEvent) {
		if (e.which == 45) {
			// '-' (MINUS, HYPHEN, DASH)
			e.preventDefault();
		}
	}
}
