import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationComponent } from './component/authentication/authentication.component';
// import { OktaCallbackComponent } from '@okta/okta-angular';
import { OKTA } from '@core/core.constants';

export enum AUTH_ROUTES {
	AUTHENTICATION = 'authentication',
}

const routes: Routes = [
	{ path: AUTH_ROUTES.AUTHENTICATION, component: AuthenticationComponent },
	{ path: OKTA.LoginCallback, component: AuthenticationComponent },
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthRoutingModule {}
