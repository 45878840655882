import { Directive, HostListener } from '@angular/core';
@Directive({
	selector: '[stringOnlyDirective]',
})
export class StringOnlyDirective {
	@HostListener('keydown', ['$event'])
	confirm(e: KeyboardEvent) {
		if ((e.which >= 48 && e.which <= 57) || (e.which >= 96 && e.which <= 105)) {
			e.preventDefault();
		}
	}
}
