import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MaintenanceService } from '../services/maintenance.service';

@Injectable()
export class MaintenanceGaurd implements CanActivate {
    maintenanceData: boolean = false;
    constructor(private maintenanceService: MaintenanceService, private router: Router) {}

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        this.maintenanceService.getStatus().subscribe({
            next: (res: { status: boolean; endTime: string }) => {
                this.maintenanceData = res.status;
            },
        });
        if (this.maintenanceData) {
            return true;
        } else {
            this.router.navigate(['/']);
            return false;
        }
    }
}
