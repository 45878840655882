<div class="container-fluid px-4 criteria-container">
    <div class="row mb-dot75">
        <div class="col">
            <div class="guide-lines">{{passwordDetails.GuideLineHeader}}</div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="list-bg list-style">
                <ul class="pl-2em mb-0 first-ul">
                    <li class="disc" [innerHtml]="passwordDetails.GuideLineSubHeader"></li>
                    <li>
                        <ul class="pl-4 second-ul">
                            <li class="disc li-first">{{passwordDetails.criteriaFirst}}</li>
                            <li class="disc li-second">{{passwordDetails.criteriaSecond}}</li>
                            <li class="disc li-third" [innerHtml]="passwordDetails.criteriaThird"></li>
                            <li class="disc li-fourth">{{passwordDetails.criteriaFourth}}</li>
                        </ul>
                    </li>
                    <li class="disc li-last" [innerHtml]="passwordDetails.criteriaLast"></li>
                </ul>
            </div>
        </div>
    </div>
</div>