import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthorizationTokenInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const exceptionURLList = environment.msalProtectedRoutes.map(routes => routes.url);
		if (exceptionURLList.includes(req.url)) {
			return next.handle(req);
		}

		if (req.url.indexOf('stqabeswestus001') == -1) {
			const authorizedRequest = req.clone({
				headers: req.headers.set('authorization', AuthService.getAuthorizationToken() ? `Bearer ${AuthService.getAuthorizationToken()}` : ''),
			});
			return next.handle(authorizedRequest).pipe(
				catchError(error => {
					if (error.status === 401 || error.status === 403) {
						AuthService.sessionErrorNotification$.next({ status: error.status });
					}
					return throwError(error);
				}),
			);
		}
		return next.handle(req);
	}
}
