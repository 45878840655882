import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { userAuthDetails } from '@auth/model/authDetails.modal';
import { RoleService } from '@auth/services/role.service';
import { UserRoles } from '@core/core.constants';
import { MaintenanceService } from './services/maintenance.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Router } from '@angular/router';
import { CoreConstants } from '@core/core.constants';
import { HomeService } from '@layouts/home/services/home.service';

dayjs.extend(utc);
dayjs.extend(timezone);

declare let $: any;
@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MaintenanceComponent implements OnInit {
    authDetails: string | undefined = '';
    endTime: string = '';
    constructor(private roleService: RoleService, private maintenanceService: MaintenanceService, private router: Router, private homeService: HomeService) {
        const authDetails: userAuthDetails | null = this.roleService.getAuthorizationPayload();
        this.authDetails = authDetails?.role;
        this.homeService.verifyMaintenanceWindow().subscribe({
            next: (res: { status: boolean; endTime: string }) => {
                this.maintenanceService.setStatus(res);
                this.onCheckMaintenance();
            },
            error: () => {},
        });
    }

    ngOnInit(): void {}

    public get isUserSuperAdmin(): boolean {
        return this.authDetails == UserRoles.clientSuperAdmin ? true : false;
    }

    onCheckMaintenance(): void {
        // const timeEnd = '2023-04-13T09:30:00';
        this.maintenanceService.getStatus().subscribe({
            next: (res: { status: boolean; endTime: string }) => {
                if (res.endTime !== null) {
                    const timeZone = dayjs.tz.guess();
                    const dayjsLocal = dayjs.utc(res.endTime).tz(timeZone).format('M/D/YYYY');
                    const dayjsHours = dayjs.utc(res.endTime).tz(timeZone).format('HH:mm');
                    const localTimeZone: string | null = new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);
                    // var usertimezone = 'America/Los_Angeles';
                    // var usertimezoneabbreviation = new Date().toLocaleTimeString('en-us', { timeZone: timeZone, timeZoneName: 'short' }).split(' ')[2];
                    this.endTime = dayjsLocal + ' at ' + dayjsHours + ' ' + localTimeZone?.match(/\b(\w)/g)?.join('');
                }
            },
            error: () => {},
        });
    }

    onTerminateMode(): void {
        this.maintenanceService.terminateMaintenanceWindow().subscribe({
            next: () => {
                $('#terminateModeModal').modal('hide');
                this.router.navigateByUrl(CoreConstants.dashboardPath);
            },
            error: () => {
                $('#terminateModeModal').modal('hide');
            },
        });
    }
}
