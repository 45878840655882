import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, of } from 'rxjs';
import { catchError, delay, mergeMap, retryWhen } from 'rxjs/operators';
import { ToasterService } from '@shared/services/toaster.service';
import { HttpErrorService } from 'src/app/layouts/error/service/HttpError.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { StatusCodes } from '@layouts/error/constants/status-codes';
import { OKTA, UserMessage } from '@core/core.constants';
import { environment } from '@environments/environment';
import { AuthService } from '@auth/services/auth.service';
import { AUTHORIZATION_SESSION_KEY } from '@core/core.constants';
import { LoginService } from '@modules/login/services/login.service';
import { MaintenanceService } from '@layouts/maintenance/services/maintenance.service'

@Injectable({
    providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
    maxRetries = 1;
    delayMilliSecs = 1000;
    clearTime: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toasterService: ToasterService,
        private httpErrorService: HttpErrorService,
        @Inject(OKTA_AUTH) private oktaService: OktaAuth,
        private authService: AuthService,
        private loginService: LoginService,
		private maintenanceService: MaintenanceService
    ) {
    sessionStorage.setItem('isLogout', JSON.stringify(false));
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            retryWhen(error => {
                return error.pipe(
                    mergeMap((error: HttpErrorResponse, index: number) => {
                        if (index < this.maxRetries && error.status == 500 && request.method == 'GET') {
                            return of(error).pipe(delay(this.delayMilliSecs));
                        }
                        throw error;
                    }),
                );
            }),
            catchError(async (err: HttpErrorResponse) => {
                let errorMessage = '';
                if (err.error instanceof ErrorEvent) {
                    errorMessage = err.error.message;
                    switch (err.status) {
                        case 404: {
                            this.router.navigate(['error', err.status], {
                                relativeTo: this.route,
                            });
                            break;
                        }
                        default:
                            break;
                    }
                } else if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'text/plain') {
                    return new Promise<any>((_resolve, reject) => {
                        let reader = new FileReader();
                        reader.onload = (e: any) => {
                            try {
                                const errmsg: string = e.target.result;
                                this.toasterService.toastFailure(errmsg, 3000, 'toast-right');
                                reject(
                                    new HttpErrorResponse({
                                        error: errmsg,
                                        headers: err.headers,
                                        status: err.status,
                                        statusText: err.statusText,
                                    }),
                                );
                            } catch (e) {
                                reject(e);
                            }
                        };
                        reader.onerror = e => {
                            reject(e);
                        };
                        reader.readAsText(err.error);
                    });
                } else {
                    errorMessage = err?.status + '' + err.message;
                    switch (err.status) {
                        case 500:
                        case 502:
                        case 504: {
                            this.router.navigate(['server-connectivity-error'], {
                                relativeTo: this.route,
                            });
                            break;
                        }
                        case 503: {
                            if (err.error.message == 'Application is under planned maintenance') {
                                this.router.navigate(['maintenance'], {
                                    relativeTo: this.route,
                                });
                                this.maintenanceService.setStatus({status: true, endTime: ''});
                            } else {
                                this.router.navigate(['server-connectivity-error'], {
                                    relativeTo: this.route,
                                });
                            }
                            break;
                        }
                        case 599: {
                            this.router.navigate(['error', err.status], {
                                relativeTo: this.route,
                            });
                            break;
                        }
                        case 511: {
                            this.router.navigate(['error', err.status], {
                                relativeTo: this.route,
                            });
                            break;
                        }
                        case 401: {
                            let errMessage = '';
                            StatusCodes.forEach((status: { code: string; phrase: string }) => {
                                if (status.code.includes(err.status.toString())) {
                                    errMessage = status.phrase;
                                }
                            });
                            errMessage = err.error ? err.error : errMessage;
                            const authenticated = sessionStorage.getItem(AUTHORIZATION_SESSION_KEY);
                            const isLogoutClick = sessionStorage.getItem('isLogout');
                            if (authenticated && !isLogoutClick) {
                                this.router.navigate(['error', err.status], {
                                    relativeTo: this.route,
                                });
                            } else {
                                if (OKTA.oktaInternalUrls.includes(window.location.origin) && environment.isOKTAEnabled) {
                                    localStorage.clear();
                                    sessionStorage.clear();
                                    await this.authService.isAuthenticationCompleted$.next(false);
                                    await this.oktaService.signOut();
                                    await this.oktaService.tokenManager.clear();
                                } else {
                                    this.toasterService.toastFailure(errMessage, 3000, 'toast-right');
                                    clearTimeout(this.clearTime);
                                    sessionStorage.clear();
                                    this.clearTime = setTimeout(() => {
                                        this.router.navigate(['login'], { relativeTo: this.route });
                                    }, 1000);
                                }
                            }
                            this.loginService.setRevokeMessage(err.error);
                            break;
                        }
                        case 404: {
                            this.router.navigate(['error', err.status], {
                                relativeTo: this.route,
                            });
                            const errorMessage = err.error.errors ? Object.values(err.error.errors)[0] : err.error;
                            if(UserMessage.UserNotFound.includes(errorMessage.toLowerCase())) {
                                this.toasterService.toastFailure(errorMessage, 3000, 'toast-right');
                                this.loginService.setInvalidMessage({message:errorMessage, status: err.status});
                            }
                            break;
                        }
                        case 403: {
                            this.router.navigate(['error', err.status], {
                                relativeTo: this.route,
                            });
                            break;
                        }
                        case 400: {
                            const errorMessage = err.error.errors ? Object.values(err.error.errors)[0] : err.error;
                            this.toasterService.toastFailure(errorMessage, 3000, 'toast-right');
                            this.loginService.setInvalidMessage({message:errorMessage, status: 400});
                            break;
                        }
                        default:
                            break;
                    }
                    this.httpErrorService.addError({
                        code: err.status,
                        message: err.message || err?.statusText,
                    });
                }
                return throwError(errorMessage);
            }),
        );
    }
}
