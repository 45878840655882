import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtTokenResponse } from '@auth/auth.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpRequestService } from 'src/app/core/services/http-request/http-request.service';
import { ApiBaseUrl } from 'src/app/shared/services/apiBaseUrl.service';
import { AUTHORIZATION_DETAILS } from '../constants/auth.constants';
import { authDetails, userAuthDetails } from '../model/authDetails.modal';

@Injectable({
	providedIn: 'root',
})
export class RoleService {
	private _isAuthorizationFailed$ = new BehaviorSubject<unknown>(null);
	private _authorizationTokenGenerated$ = new BehaviorSubject<authDetails | null>(null);

	constructor(private http: HttpClient, private urlService: ApiBaseUrl, public httpService: HttpRequestService) {}

	public get isAuthorizationFailed$(): Observable<unknown> {
		return this._isAuthorizationFailed$;
	}

	public get authorizationTokenGenerated$(): Observable<authDetails | null> {
		return this._authorizationTokenGenerated$;
	}

	public getJwtAccessToken(): Observable<userAuthDetails> {
		const roleApiEndpoint = this.urlService.getOrganizationApiUrl('api/user/getjwttoken', null, true);
		const jwtToken = this.httpService.getData(roleApiEndpoint);
		return jwtToken.pipe(
			tap(tokenModel => {
				this.authorizationPayload = tokenModel;
				sessionStorage.setItem('token', tokenModel.authorizationToken ?? '');
			}),
		);
	}

	public getDealBasedAccessToken(dealId: string): Observable<userAuthDetails> {
		let lastDealId = sessionStorage.getItem('dealId');
		if (lastDealId === dealId && this.authorizationPayload) {
			return of(this.authorizationPayload);
		}
		const roleApiEndpoint = this.urlService.getOrganizationApiUrl('api/user/getuserdealjwttoken', `dealId=${dealId}`, true);
		const response = this.httpService.getData(roleApiEndpoint);
		return response.pipe(
			tap(tokenModel => {
				this.authorizationPayload = { authorizationToken: tokenModel.authorizationToken, role: tokenModel.role, permissions: tokenModel.permissions, viewMultiDealScreen: this.authorizationPayload?.viewMultiDealScreen, multiDealScreenCapabilities: this.authorizationPayload?.multiDealScreenCapabilities };
				sessionStorage.setItem('token', tokenModel.authorizationToken ?? '');
				sessionStorage.setItem('dealId', dealId);
			}),
		);
	}

	private set authorizationPayload(payload: userAuthDetails | null) {
		sessionStorage.setItem(AUTHORIZATION_DETAILS, payload ? JSON.stringify(payload) : '');
	}

	private get authorizationPayload(): userAuthDetails | null {
		const payload = sessionStorage.getItem(AUTHORIZATION_DETAILS) || '';
		return payload ? JSON.parse(payload) : null;
	}

	public getAuthorizationPayload(): userAuthDetails | null {
		return this.authorizationPayload;
	}

	public getOktaJwtAccessToken(): Observable<JwtTokenResponse> {
		const jwt: string = localStorage.getItem('oktaToken') as string;
		const header = new HttpHeaders({ Authorization: `Bearer ${jwt}` });
		const options = {
			headers: header,
		};
		const roleApiEndpoint = this.urlService.getOrganizationApiUrl('api/user/getjwttoken');
		const jwtToken = this.http.get(roleApiEndpoint, options);
		return jwtToken.pipe(
			tap((tokenResponse: any) => {
				this.authorizationPayload = { authorizationToken: tokenResponse.token, role: tokenResponse.role, permissions: tokenResponse.permissions };
				sessionStorage.setItem('token', tokenResponse.token);
			}),
		);
	}
}
