import { NgModule } from '@angular/core';
import { TableFontFamilyChangeDirective } from '@shared/components/responsive-table/directives/tableFamily.directive';
import { CustomPhoneNumberDirective } from '@shared/directives/customPhoneNumber.directive';
import { FontFamilyChangeDirective } from '@shared/directives/fontFamily.directive';
import { LazyImageDirective } from '@shared/directives/lazyImage.directive';
import { NumberOnlyDirective } from '@shared/directives/numberOnly.directive';
import { RestrictSpecialCharsDirective } from '@shared/directives/restrictSpecialChars.directive';
import { DisableRightClickDirective } from '@shared/directives/rightClick.directive';
import { StringOnlyDirective } from '@shared/directives/stringOnly.directive';

@NgModule({
	declarations: [
		LazyImageDirective,
		NumberOnlyDirective,
		StringOnlyDirective,
		RestrictSpecialCharsDirective,
		FontFamilyChangeDirective,
		CustomPhoneNumberDirective,
		TableFontFamilyChangeDirective,
		DisableRightClickDirective,
	],
	imports: [],
	providers: [],
	exports: [
		LazyImageDirective,
		NumberOnlyDirective,
		StringOnlyDirective,
		RestrictSpecialCharsDirective,
		FontFamilyChangeDirective,
		CustomPhoneNumberDirective,
		TableFontFamilyChangeDirective,
		DisableRightClickDirective,
	],
})
export class DirectivesModule {}
