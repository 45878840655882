import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthorizationTokenInterceptor implements HttpInterceptor {
	constructor() {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const exceptionURLList = environment.msalProtectedRoutes.map(routes => routes.url);

		if (exceptionURLList.includes(req.url)) {
			return next.handle(req);
		}
		if (req.url.indexOf('stqabeswestus001') == -1) {
			const authorizedRequest = req.clone({
				headers: req.headers.set(
					'authorization',
					AuthService.getAuthorizationToken() ? `Bearer ${AuthService.getAuthorizationToken()}` : `Bearer ${sessionStorage.getItem('token')}`,
				),
			});
			return next.handle(authorizedRequest);
		}
		return next.handle(req);
	}
}
