import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class LoaderService {
	private loader = new BehaviorSubject<any>({ id: '', status: false });

	loaderStatus$ = this.loader.asObservable();

	constructor() {}

	/**
	 * Show loader
	 * @param {string} id
	 */
	public showLoader(id: string): void {
		this.loader.next({ id, status: true });
	}

	/**
	 * Hide loader
	 * @param {string} id
	 */
	public hideLoader(id: string): void {
		this.loader.next({ id, status: false });
	}

	public containsSearchTerm(obj: any, searchTerm: string): boolean {
		for (const key of Object.keys(obj)) {
			const value = obj[key];
			if(value) {
				if (Array.isArray(value)) {
					for (const item of value) {
					  if (this.containsSearchTerm(item, searchTerm)) {
						return true;
					  }
					}
				  } else if (typeof value === 'object') {
					if (this.containsSearchTerm(value, searchTerm)) {
					  return true;
					}
				  } else {
					if (value.toString().toLowerCase().includes(searchTerm.toLowerCase())) {
					  return true;
					}
				  }
			}
		  }
		  return false;
	}
}
