import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'maskAllButLast4Chars',
	pure: true,
})
export class MaskAllButLast4CharsPipe implements PipeTransform {
	transform(value: string): any {
		if (value !== null) {
			return value.replace(/.(?=.{4})/g, "*");
		}
	}
}
