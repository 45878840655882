import { Injectable } from '@angular/core';

declare let $: any;

@Injectable({
	providedIn: 'root',
})
export class ToasterService {
	constructor() {}

	toastSuccess(msg: String, delay: number, position?: string) {
		$('body').append(`<div class="toaster-container ${position}"><div class="toaster-success toaster">` + msg + '</div></div>');
		setTimeout(() => {
			$('.toaster-container').remove();
		}, delay);
	}

	toastFailure(msg: String, delay: number, position?: string) {
		$('body').append(`<div class="toaster-container ${position}"><div class="toaster-failure toaster">` + msg + '</div></div>');
		setTimeout(() => {
			$('.toaster-container').remove();
		}, delay);
	}

	toastWarning(msg: String, delay: number, position?: string) {
		$('body').append(`<div class="toaster-container ${position}"><div class="toaster-warning toaster">` + msg + '</div></div>');
		setTimeout(() => {
			$('.toaster-container').remove();
		}, delay);
	}

	toastWarning1(msg: String, delay: number) {
		$('body').append(
			'<div class="toaster-container"><div class="toaster"><div class="toaster-warning"></div><div class="toast-content">' +
				msg +
				'</div><div class="close-icon"></div></div></div>',
		);
		setTimeout(() => {
			$('.toaster-container').remove();
		}, delay);
	}
}
