import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'server-connectivity-error',
	templateUrl: './server-connectivity-error.component.html',
	styleUrls: ['./server-connectivity-error.component.scss'],
})
export class ServerConnectivityErrorComponent implements OnInit {
	
	constructor() {}

	ngOnInit(): void {
		
	}
}
