import { Component, OnDestroy, OnInit } from '@angular/core';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';

@Component({
	selector: 'app-authentication',
	templateUrl: './authentication.component.html',
	styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit, OnDestroy {
	public isLoginFailed = false;

	constructor(private oktaAuthService: OktaAuthStateService) {}

	ngOnInit(): void {
		this.authenticationFailure();
	}

	authenticationFailure(): void {
		this.oktaAuthService.authState$.subscribe((authState: AuthState) => {
			// eslint-disable-next-line no-extra-boolean-cast
			if (!!authState.error) {
				this.isLoginFailed = true;
			}
		});
	}

	// eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
	ngOnDestroy(): void {}
}
