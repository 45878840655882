import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PERMISSIONS } from '../model/permissions.enum';
import { AuthService } from '../services/auth.service';

@Directive({
	selector: '[displayForPermissions]',
})
export class DisplayForPermissionsDirective implements OnInit {
	@Input() displayForPermissions: PERMISSIONS[] = [];

	constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {}

	ngOnInit() {
		if (this.isUserAllowed()) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			return;
		}
		this.viewContainer.clear();
	}

	private isUserAllowed(): boolean {
		const usersPermission = this.authService.permissionList;

		return this.displayForPermissions.some(permission => usersPermission.includes(permission));
	}
}
