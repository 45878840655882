import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
	providedIn: 'root',
})
export class DealsInfoService {
	private dealsDetails = new BehaviorSubject<any>('');

	dealName = this.dealsDetails.asObservable();

	constructor() {}

	setDealsInfo(dealsName: string): void {
		this.dealsDetails.next(dealsName);
	}

	getDealsInfo(): Observable<string> {
		return this.dealName;
	}
}
