/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/** Core Packages */
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

/** Packages */
import { filter, map, mergeMap } from 'rxjs/operators';

const TITLE = 'Gateway';
const SEPERATOR = ' |';

@Injectable({
	providedIn: 'root',
})
export class TitleService {
	titleName = '';
	constructor(private titleService: Title, private activatedRoute: ActivatedRoute, private router: Router) {}

	onChangeRouteTitle(): void {
		this.router.events
			.pipe(
				filter(event => event instanceof NavigationEnd),
				map(() => this.activatedRoute),
				map(data => {
					while (data.firstChild) data = data.firstChild;
					return data;
				}),
				filter(route => route.outlet == 'primary'),
				mergeMap(route => route.data),
				map(data => {
					if (data.title) {
						return data.title;
					} else {
						const currentUrl = this.router.url.substr(1);
						const currentUrlSplit = currentUrl.split('?');
						const levelSplit = currentUrlSplit[0].split('/');
						if (levelSplit[0] == '') {
							levelSplit[0] = 'Login';
						} else {
							levelSplit[0] = levelSplit[0];
						}
						return `${SEPERATOR} ${this.convertCase(levelSplit[0])}`;
					}
				}),
			)
			.subscribe(event => this.titleService.setTitle(`${TITLE}${event}`));
	}

	private convertCase(pathString: string): any {
		if (!pathString) return pathString;
		this.titleName = pathString.charAt(0).toUpperCase() + pathString.slice(1);
		return `${this.titleName}`;
	}
}
