import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'searchFilter',
	pure: false,
})
export class SearchFilterPipe implements PipeTransform {
	transform(value: Array<any>, args?: any): any {
		// eslint-disable-next-line curly
		if (!value) return null;
		// eslint-disable-next-line curly
		if (!args) return value;

		const regexp = new RegExp(args, 'i');
		const keyList = Object.keys(value[0]);
		const index = keyList.indexOf('paymentEvents');
		if (index > -1) {
			keyList.splice(index, 1);
		}

		return [
			...value.filter(item => {
				return keyList.some(property => regexp.test(item[property]));
			}),
		];
	}
}
