import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import { CoreConstants } from '../core.constants';

@Injectable({
	providedIn: 'root',
})
export class AESEncryptDecryptService {
	secretKey = CoreConstants.secureKey;
	constructor() {}

	encrypt(value: string): string {
		return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
	}

	decrypt(textToDecrypt: string) {
		return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
	}
}
