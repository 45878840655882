import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { OTPInputComponent } from '@shared/components/otp-input/otp-input.component';
import { DirectivesModule } from '../directive.module';


@NgModule({
	declarations: [OTPInputComponent],
	imports: [CommonModule, FormsModule, DirectivesModule],
	providers: [],
	exports: [OTPInputComponent],
})
export class OTPInputModule {}
