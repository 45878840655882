import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth.routing.module';
import { DisplayForPermissionsDirective } from './directive/displayForPermissions.directive';
import { PermissionRequiredDirective } from './directive/permissionRequired.directive';
import { RoleRequiredDirective } from './directive/roleRequired.directive';
import { AuthGuard } from './gaurds/auth.gaurd';
import { LoggedInGaurd } from './gaurds/loggedin.gaurd';
import { PermissionsGaurd } from './gaurds/permissions.gaurd';
import { RoleGaurd } from './gaurds/role.gaurd';
import { AuthorizationTokenInterceptor } from './interceptor/authorizationToken.interceptor';

import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '@environments/environment';
import { MaintenanceGaurd } from '@layouts/maintenance/guards/maintenance.guard';

const oktaAuthConfig = new OktaAuth(environment.oktaConfig);

@NgModule({
	imports: [CommonModule, OktaAuthModule, AuthRoutingModule],
	declarations: [DisplayForPermissionsDirective, PermissionRequiredDirective, RoleRequiredDirective],
	providers: [
		PermissionsGaurd,
		RoleGaurd,
		AuthGuard,
		LoggedInGaurd,
		MaintenanceGaurd,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthorizationTokenInterceptor,
			multi: true,
		},
		{
			provide: OKTA_CONFIG,
			useValue: { oktaAuth: oktaAuthConfig },
		},
	],
	exports: [DisplayForPermissionsDirective, PermissionRequiredDirective, RoleRequiredDirective],
})
export class AuthModule {}
