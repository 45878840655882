import { Directive, ElementRef, Input, OnInit, OnChanges } from '@angular/core';

@Directive({
    selector: '[tableFontFamilyDirective]',
})
export class TableFontFamilyChangeDirective implements OnInit, OnChanges {
    @Input() tableFontFamilyDirective: string | any = '';
    // seperatorList = ['out of', '-'];

    constructor(private el: ElementRef) {}

    ngOnInit() {
        if (this.tableFontFamilyDirective !== null && this.tableFontFamilyDirective !== undefined) {
            if (!this.tableFontFamilyDirective || typeof this.tableFontFamilyDirective !== 'string') {
                this.el.nativeElement.classList.add('OpenSansRegular', 'fs-12px');
            } else {
                // const data: string = this.tableFontFamilyDirective?.match(/\d.+/)[0];
                const data: any = this.tableFontFamilyDirective?.match(/(-\d+|\d+)(,\d+)*(\.\d+)*/g);
                // if (new String(data).length > 0) {
                if (data !== null) {
                    this.el.nativeElement.classList.add('RobotoRegular', 'fs-12px');
                } else {
                    this.el.nativeElement.classList.add('RobotoRegular');
                }
            }
        }
    }

    ngOnChanges() {
        if (this.tableFontFamilyDirective !== null && this.tableFontFamilyDirective !== undefined) {
            if (!this.tableFontFamilyDirective || typeof this.tableFontFamilyDirective !== 'string') {
                this.el.nativeElement.classList.add('RobotoRegular', 'fs-12px');
            } else {
                // const data: string = this.tableFontFamilyDirective?.match(/\d.+/)[0];
                const data: any = this.tableFontFamilyDirective?.match(/(-\d+|\d+)(,\d+)*(\.\d+)*/g);
                // if (new String(data).length > 0) {
                if (data !== null) {
                    this.el.nativeElement.classList.add('RobotoRegular', 'fs-12px');
                } else {
                    this.el.nativeElement.classList.add('RobotoRegular');
                }
            }
        }
    }
}
