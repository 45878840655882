import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpRequestService } from '@core/services/http-request/http-request.service';
import { CoreConstantsApi } from '@core/core.constants';
import { ApiBaseUrl } from '@shared/services/apiBaseUrl.service';
import { BatchJobHealth, K2SyncLog } from '../models/maintenanceWindow.model';

@Injectable({
  providedIn: 'root',
})
export class HomeService {

  constructor(public http: HttpClient, public httpService: HttpRequestService, private apiService: ApiBaseUrl) { }

  addMaintenanceWindow(maintenanceWindow: any): Observable<any[]> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.addMaintenanceWindow);
    return this.httpService.postData(apiurl, maintenanceWindow);
  }

  updateMaintenanceWindow(maintenanceWindow: any): Observable<any[]> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.updateMaintenanceWindow);
    return this.httpService.patchData(apiurl, maintenanceWindow);
  }

  nextMaintenanceWindow(): Observable<any> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.nextMaintenanceWindow);
    return this.httpService.getData(apiurl);
  }

  verifyMaintenanceWindow(): Observable<any> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.checkMaintenanceWindow);
    return this.httpService.getData(apiurl);
  }

  logout(): Observable<any> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.logout);
    return this.httpService.postData(apiurl, null);
  }

  getBatchJobHealthInfo(): Observable<BatchJobHealth> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.batchJobHealthCheck);
    return this.httpService.getData(apiurl);
  }

  getK2SyncLogsInfo(): Observable<K2SyncLog> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.k2SyncLogs);
    return this.httpService.getData(apiurl);
  }
}
