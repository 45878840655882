<div class="authentication d-flex justify-content-center align-items-center">
	<div class="d-flex flex-column justify-content-center align-items-center">
		<div *ngIf="!isLoginFailed" class="d-flex flex-column welcome-text align-items-center">
			<div class="bank-image">
				<img alt="logo" src="assets/images/black-bridge.svg" />
			</div>
			<div class="box">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<div class="content">
					<div class="card text-center">
						<div class="card-body">
							<div class="py-1">Welcome,</div>
							<div class="py-1">We are authenticating your credentials,</div>
							<div class="py-1">Please wait...</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
