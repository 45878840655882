import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpRequestService } from '@core/services/http-request/http-request.service';
import { CoreConstantsApi } from '@core/core.constants';
import { ApiBaseUrl } from '@shared/services/apiBaseUrl.service';
import { AccountsOverview, Alert, DealsDetails, Vesting, AccountForDealsModel, SolicitationCardInfo, BlobDoc } from '../models/deals.model';

@Injectable({
  providedIn: 'root',
})
export class DealsService {
  dealParty: any;
  constructor(public http: HttpClient, public httpService: HttpRequestService, private apiService: ApiBaseUrl) {}

  //get all the deals details
  getDealsDetails(dealId: number): Observable<DealsDetails> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.dealDetails, `dealId=${dealId}`);
    return this.httpService.getData(apiurl);
  }

  //get all the team contact details
  getTeamContacts(dealId: number): Observable<any> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.contacts, `dealId=${dealId}`);
    return this.httpService.getData(apiurl);
  }

  //get all the team contact details
  getAlerts(dealId: number): Observable<Alert[]> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.alerts, `dealId=${dealId}`);
    return this.httpService.getData(apiurl);
  }

  //get all the reports for a particular deal
  getReports(dealId: number): Observable<Document[]> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.reports, `dealId=${dealId}`);
    return this.httpService.getData(apiurl);
  }

  //get all the selected deal payment Account Activity
  getPaymentAccountActivity(dealId: number,childDealId: number): Observable<DealsDetails> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.paymentAccountActivity, `dealId=${dealId}&childId=${childDealId}`);
    return this.httpService.getData(apiurl);
  }

  //get all the deal documents for a  particular deal
  getDealDocuments(dealId: number): Observable<Document[]> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.dealDocuments, `dealId=${dealId}&noOfRecords=6`);
    return this.httpService.getData(apiurl);
  }

  //download deal document with specific Id
  downloadDealDocument(dealId: number, documentId: number): Observable<Blob> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.downloadDealDocument, `dealId=${dealId}&documentId=${documentId}`);
    return this.httpService.getFile(apiurl);
  }

  //get all the selected deal solicitation summary Activity
  // getSolicitationsSummary(dealId: number): Observable<DealsDetails> {
  //   let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.solicitationsSummary, `dealId=${dealId}`);
  //   return this.httpService.getData(apiurl);
  // }

  //get all the Documents Types for solicitations filter from parent-child deals
  getSolicitationsSummaryDocumentsTypes(dealId: number): Observable<any[]> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.solicitationsSummaryDocumentsTypes, `dealId=${dealId}`);
    return this.httpService.getData(apiurl);
  }

  //get all the Documents Types for solicitations filter from parent-child deals
  getSolicitationsSummaryBulkDownload(dealId: number, type: string, initDate: string, endDate: string): Observable<Blob> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.solicitationsSummaryBulkDownload, `dealId=${dealId}&type=${type}&initDate=${initDate}&endDate=${endDate}`);
    return this.httpService.getFile(apiurl);
  }

  getSolicitationsSummaryBulkDownloadBlobs(dealId: number, type: string, initDate: string, endDate: string): Observable<any[]> {
    let apiUrl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.solicitationsSummaryBulkDownloadBlobs, `dealId=${dealId}&type=${type}&initDate=${initDate}&endDate=${endDate}`);
    return this.httpService.getData(apiUrl);
  }

  getBlob(blob: string): Promise<Blob> {
    return this.http.get(blob, { responseType: 'blob' }).toPromise();
  }

  getBlobUrl(blob: string):string{
    return this.apiService.getOrganizationApiUrl(CoreConstantsApi.downloadBlob, `blobName=${encodeURIComponent(blob)}`);
  }

  //get all the selected Overview Documents from solicitations screen
  getSolicitationsSummaryDocumentsOverview(dealId: number): Observable<DealsDetails> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.solicitationsSummaryDocumentsOverview, `dealId=${dealId}`);
    return this.httpService.getData(apiurl);
  }

  //get all the selected deal accounts Overview Activity
  getAccountsOverview(dealId: number): Observable<AccountsOverview> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.accountsOverview, `dealId=${dealId}`);
    return this.httpService.getData(apiurl);
  }

  //download statement of each account with specific Deal Id and Account number
  downloadStatement(dealId: number, childDealId: number, accountNumber: string): Observable<any> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.accountsOverviewStatement, `dealId=${dealId}&childDealId=${childDealId}&accountNumber=${accountNumber}`);
    return this.httpService.getFile(apiurl);
  }

  //get all the selected deal Vesting Details
  getVestingDetails(dealId: number): Observable<Vesting[]> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.vesting, `dealId=${dealId}`);
    return this.httpService.getData(apiurl);
  }

  //download deal document with specific Id
  downloadPaidUnpaidReport(dealId: number, reportName: string): Observable<Blob> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.downloadPaidUnpaidReport, `dealId=${dealId}&reportName=${reportName}`);
    return this.httpService.getFile(apiurl);
  }

  downloadSolicitationsReport(dealId: number, reportName: string): Observable<Blob> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.downloadSolicitationReport, `dealId=${dealId}&reportName=${reportName}`);
    return this.httpService.getFile(apiurl);
  }

  //get all the overview drilldownData
  getOverviewDrilldown(dealId: number): Observable<any[]> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.overviewDrilldown, `dealId=${dealId}`);
    return this.httpService.getData(apiurl);
  }

  getOverviewDrilldownTransactions(dealId: number, date: string, accountNumber: string): Observable<any[]> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.overviewDrilldownTransactions, `dealId=${dealId}&date=${date}&accountNumber=${accountNumber}`);
    return this.httpService.getData(apiurl);
  }

  getOverviewDrilldownPDF(dealId: number, childDealId: number, accountNumber: string, date: string): Observable<Blob> {
    let apiurl = this.apiService.getOrganizationApiUrl(
      CoreConstantsApi.accountsOverviewStatement,
      `dealId=${dealId}&childDealId=${childDealId}&accountNumber=${accountNumber}&date=${date}`,
    );
    return this.httpService.getFile(apiurl);
  }

  //get all the deal accounts
  fetchAccountsForDeal(dealId: number): Observable<AccountForDealsModel> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.getAccountsForDeal, `dealId=${dealId}`);
    return this.httpService.getData(apiurl);
  }

  //get all the deal accounts
  fetchPayinhAccountsForDeal(dealId: number): Observable<AccountForDealsModel> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.getAccountsForDeal, `dealId=${dealId}&accountType=Paying Agent`);
		return this.httpService.getData(apiurl);
  }

  getSolicitationCardDetails(parentDealId: number,childDealId: number, recordCount: number):Observable<SolicitationCardInfo[]> {
    let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.solicitationCard, `DealId=${parentDealId}&childDealId=${childDealId}&numberOfRecords=${recordCount}`);
    return this.httpService.getData(apiurl);
  }

  // download full solicitation report
	downloadSolicitationReport(dealId: number, params: AccountForDealsModel[]): Observable<Blob> {
		let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.downloadFullSolicitationReport, `dealId=${dealId}`);
		return this.http.post(apiurl, params, { responseType: 'blob' });
	}

  downloadBlob(params: BlobDoc): Observable<Blob> {
		let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.downloadBlobV2);
		return this.http.post(apiurl, params, { responseType: 'blob' });
	}
}
