import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ROLES } from '../model/roles.enum';
import { AuthService } from '../services/auth.service';

@Directive({
	selector: '[roleRequired]',
})
export class RoleRequiredDirective implements OnInit {
	@Input() roleRequired: ROLES[] = [];

	constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {}

	ngOnInit() {
		if (this.isUserAllowed()) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			return;
		}
		this.viewContainer.clear();
	}

	private isUserAllowed(): boolean {
		return this.authService.haveRole(this.roleRequired);
	}
}
