import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpRequestService } from '@core/services/http-request/http-request.service';
import { CoreConstantsApi } from '@core/core.constants';
import { ApiBaseUrl } from '@shared/services/apiBaseUrl.service';

@Injectable({
	providedIn: 'root',
})
export class MaintenanceService {
	private maintenanceSubject = new BehaviorSubject<{status: boolean, endTime: string}>({status: false, endTime: ''});
	status = this.maintenanceSubject.asObservable();

	constructor(public httpService: HttpRequestService, private apiService: ApiBaseUrl) {}

	setStatus(status: {status: boolean, endTime: string}): void {
		if (status) {
			this.maintenanceSubject.next(status);
		}
	}

    getStatus(): Observable<{status: boolean, endTime: string}> {
        return this.status;
    }

	terminateMaintenanceWindow(): Observable<any> {
		let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.terminateMaintenanceMode);
		return this.httpService.patchData(apiurl, {});
	}
}
