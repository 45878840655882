import { Pipe, PipeTransform } from '@angular/core';
import { TeamContact } from '@deals/models/deals.model';

@Pipe({
	name: 'filter',
})
export class TeamContactPipe implements PipeTransform {
	aux: any;

	transform(teamContacts: TeamContact[], find: string): TeamContact[] {
		if (!teamContacts) return [];
		if (!find) return teamContacts;
		return search(teamContacts, find);
	}
}

function search(entries: TeamContact[], search: string) {
	search = search.toLowerCase();
	return entries.filter(function (obj: { [x: string]: any }) {
		delete obj['contactType'];
		const keys: string[] = Object.keys(obj);
		return keys.some(function (elmt) {
			const value = obj[elmt];
			const returner = value.toString().toLowerCase().includes(search);
			return returner;
		});
	});
}

@Pipe({
	name: 'titleInfoPipe',
	pure: false,
})
export class TitleInfomationPipe implements PipeTransform {
	transform(value: string): any {
		if (value) {
			let initials = value.substr(0, 1).toUpperCase();
			if (value.length > 3) {
				initials += value.substr(1).toLowerCase();
			} else {
				initials = value;
			}
			return initials;
		}
	}
}
