import { Component, OnInit } from '@angular/core';
import { PasswordCriteria } from '@core/core.constants';
import { PasswordCriteriaDetails } from '@modules/forgot-password/models/ForgotPassDetails.model';

@Component({
    selector: 'app-password-criteria',
    templateUrl: './password-criteria.component.html',
    styleUrls: ['./password-criteria.component.scss']
})

export class PasswordCriteriaComponent implements OnInit {
	passwordDetails: PasswordCriteriaDetails = PasswordCriteria;

    constructor(){}

    ngOnInit(): void {
    }
}