import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'InitialSplice',
	pure: false,
})
export class InitialSplicePipe implements PipeTransform {
	transform(value: string | null): any {
		if (value) {
			const names = value.split(' ');
			let initials = names[0].substring(0, 1).toUpperCase();

			if (names.length > 1) {
				initials += names[names.length - 1].substring(0, 1).toUpperCase();
			}
			return initials;
		}
	}
}
