import { NgModule } from '@angular/core';
import { TitleInfomationPipe } from '@modules/deals/components/team-contact/components/teamcontactdrilldown/pipes/team-contact.pipe';
import { EmailTruncatePipe } from '@shared/pipes/emailTruncate.pipe';
import { MultiConversionTablePipe } from '@shared/pipes/multiConverseTable.pipe';
import { SearchFilterPipe } from '@shared/pipes/searchFilter.pipe';
import { TruncateString } from '@shared/pipes/truncateString.pipe';
import { CurrencyFormatPipe } from '../pipes/currencyFormat.pipe';
import { InitialSplicePipe } from '../pipes/initialSplice.pipe';
import { TruncateTextPipe } from '../pipes/truncateText.pipe';
import { MaskAllButLast4CharsPipe } from '@shared/pipes/maskAllButLast4Chars.pipe';
import { TableKeysPipe } from '@shared/pipes/tableKeys.pipe';
import { TextBoldPipe } from '@shared/pipes/TextBoldPipe';

@NgModule({
	declarations: [TruncateTextPipe, CurrencyFormatPipe, InitialSplicePipe, MultiConversionTablePipe, EmailTruncatePipe, SearchFilterPipe, TitleInfomationPipe, TruncateString, MaskAllButLast4CharsPipe, TableKeysPipe, TextBoldPipe],
	imports: [],
	providers: [],
	exports: [TruncateTextPipe, CurrencyFormatPipe, InitialSplicePipe, MultiConversionTablePipe, EmailTruncatePipe, SearchFilterPipe, TitleInfomationPipe, TruncateString, MaskAllButLast4CharsPipe, TableKeysPipe, TextBoldPipe],
})
export class PipesModule {}
