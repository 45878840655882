import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ROLES } from '@auth/model/roles.enum';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class RoleGaurd implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		const rolesRequired = route.data?.roles as ROLES[];
		if (this.authService.haveRole(rolesRequired)) {
			return true;
		} else {
			this.router.navigate(['dashboard']);
		}
		return false;
	}
}
