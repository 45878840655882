/** Default pacakges */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
/** Third party packages */
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
/** Routing */
import { AppRoutingModule } from './app-routing.module';
/** Component */
import { AppComponent } from './app.component';
import { AuthenticationComponent } from './auth/component/authentication/authentication.component';
// import { ErrorComponent } from '@layouts/error/error.component';
/** Service */
import { AuthorizationTokenInterceptor } from './core/services/httpInterceptor/authorization.interceptor';
import { AppConfigService } from './core/services/appConfig/appConfig.service';
import { AuthModule } from './auth/auth.module';
import { HttpErrorInterceptor } from '@auth/interceptor/error.interceptor';
import { MaintenanceComponent } from '@layouts/maintenance/maintenance.component'
import { ModalDialogModule } from '@shared/modules/shared/modal-dialog.module';
import { UpdatePasswordModule } from '@modules/update-password/update-password.module';
import { PreviewUserComponent } from '@modules/Users/user-management/components/preview-user/preview-user.component';

// eslint-disable-next-line
export function InitializeApp(appConfig: AppConfigService) {
	return () => appConfig.load();
}

@NgModule({
	declarations: [
		AppComponent,
		AuthenticationComponent,
		MaintenanceComponent,
		PreviewUserComponent
		// ErrorComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		CommonModule,
		FormsModule,
		RouterModule,
		LoggerModule.forRoot({
			level: NgxLoggerLevel.TRACE,
			serverLoggingUrl: 'https://localhost:44388/api/Logging/LogInfo',
			serverLogLevel: NgxLoggerLevel.TRACE,
		}),
		AuthModule,
		ModalDialogModule,
		UpdatePasswordModule,
		AppRoutingModule,
	],
	providers: [
		AppConfigService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthorizationTokenInterceptor,
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: InitializeApp,
			deps: [AppConfigService],
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpErrorInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
