import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

export interface Loader {
	id: string;
	status: boolean;
}

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnChanges {
	@Input() public id: string = '';
	public show: boolean = false;
	constructor(private loaderService: LoaderService) {}

	public ngOnInit(): void {
		this.loaderService.loaderStatus$.subscribe((response: Loader) => {
			this.show = this.id === response.id && response.status;
		});
	}

	ngOnChanges() {
		this.loaderService.loaderStatus$.subscribe((response: Loader) => {
			this.show = this.id === response.id && response.status;
		});
	}
}
