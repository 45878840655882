import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'MultiConverseTablePipe',
	pure: true,
})
export class MultiConversionTablePipe implements PipeTransform {
	transform(value: string, strlen: number): any {
		if (!value) {
			return value;
		}
		if (value) {
			if (typeof value === 'string') {
				if (value.length > 0) {
					if (value.length <= strlen) {
						value = value[0].toUpperCase() + value.substr(1).toLowerCase();
					} else {
						value = value[0].toUpperCase() + value.substr(1).toLowerCase();
						value = value.substring(0, strlen) + '...';
					}
				}
				return value;
			} else {
				return value;
			}
		}
	}
}
