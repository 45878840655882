import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DealsInfoService } from '@shared/services/deals.service';
import { DealsDetails } from '@deals/models/deals.model';
import { DealsService } from '@deals/services/deals.service';
import { LoginService } from '@modules/login/services/login.service';
import { TitleService } from '@shared/services/title.service';
import { HomeService } from '@layouts/home/services/home.service';
import { RouteURLS, CoreConstants } from '@core/core.constants';
import { MaintenanceService } from '@layouts/maintenance/services/maintenance.service'
import { environment } from '@environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	isLoggedIn: boolean = false;
	dealId: string | null = '';
	isLoading = false;
	title = 'BES-UI';

	constructor(
		private loginService: LoginService,
		private titleService: TitleService,
		private router: Router,
		private dealService: DealsService,
		private dealsInfoService: DealsInfoService,
		private homeService: HomeService,
		private maintenanceService: MaintenanceService
	) {}

	ngOnInit() {
		this.loginService.isLoggedIn().subscribe((isUserLoggedIn: boolean)=>{
			this.isLoggedIn = isUserLoggedIn;
		});
		this.titleService.onChangeRouteTitle();
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.dealId = sessionStorage.getItem('dealId');
				if (this.router.navigated) {
					if (event.url.split('/').length > 4 && event.url.includes('dashboard')) {
						const dealId = Number(this.dealId);
						this.dealService.getDealsDetails(dealId).subscribe((deals: DealsDetails) => {
							if (deals) {
								sessionStorage.setItem('dealName', deals.dealName);
								this.dealsInfoService.setDealsInfo(deals.dealName);
							}
						});
					}
				}
			}
		});
        if (CoreConstants.externalURLs.includes(window.location.origin) && !environment.isOKTAEnabled) {
            this.homeService.verifyMaintenanceWindow().subscribe({
                next: (res: { status: boolean; endTime: string }) => {
                    this.maintenanceService.setStatus(res);
                    if (res.status) {
                        this.router.navigateByUrl(RouteURLS.maintenance);
                    } else {
                        if (!this.isLoggedIn) this.router.navigateByUrl(RouteURLS.login);
                    }
                },
                error: () => {},
            });
        }
	}
	onActivate() {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		console.log('width:',window.innerWidth,'height:',window.innerHeight);
	}
}
