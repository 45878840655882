import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorObj } from '../models/error.model';

@Injectable({
	providedIn: 'root',
})
export class HttpErrorService {
	private errorSubject = new BehaviorSubject<ErrorObj>({code: 0, message: ''});
	error = this.errorSubject.asObservable();

	constructor() {}

	addError(error: ErrorObj): void {
		if (error) {
			this.errorSubject.next(error);
		}
	}

	getError():  Observable<ErrorObj>{
		return this.error;
	}
}
