import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { LoaderComponent } from '@shared/components/loader/loader.component';

import { LoaderService } from '@shared/services/loader.service';

@NgModule({
	declarations: [LoaderComponent],
	imports: [CommonModule, FormsModule],
	providers: [LoaderService],
	exports: [LoaderComponent],
})
export class LoaderModule {}
