export enum ROLES {
	BANK_SIDE_SUPER_ADMIN = 'Bank Side Super Admin',
	RELATIONSHIP_MANAGER = 'RM',
	SENIOR_MANAGER = 'Senior Manager',
	CLIENT_SIDE_SUPER_ADMIN = 'Client Side Super Admin',
	CLIENT_SIDE_HR = 'Client Side HR',
	BUYER_SIDE_COUNSEL = 'Buyer Side Counsel',
	BUYER_SIDE_HR = 'Buyer Side HR',
	SELLER_PARTY_1 = 'Seller Party 1',
	SELLER_PARTY_2 = 'Seller Party 2',
	LAWYER = 'Lawyer',
	USER_ADMIN = 'User Admin',
}
