import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-modal-dialog',
	templateUrl: './modal-dialog.component.html',
	styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnInit, OnChanges {
	@Input() modalId = '';
	@Input() maxWidth = '';
	@Input() cancelFooter = false;
	@Input() submitFooter = false;
	@Input() showModalFooter = true;
	@Input() backdrop?: string = '';
	constructor() {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes) {
			this.showModalFooter = this.showModalFooter;
		}
	}
}
