import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class HttpRequestService {
	constructor(public http: HttpClient) {}

	getData(url: string): Observable<any> {
		return this.http.get<any>(url).pipe(
			catchError(err => {
				return throwError(err);
			}),
		);
	}

	postData(url: string, body: any): Observable<any> {
		return this.http.post<any>(url, body).pipe(
			catchError(err => {
				return throwError(err);
			}),
		);
	}

	getFile(url: string): Observable<Blob> {
		const requestOptions = {
			responseType: 'blob' as 'json',
		};
		return this.http.get<Blob>(url, requestOptions).pipe(
			catchError(err => {
				return throwError(err);
			}),
		);
	}

	getFileWithResponseHeaders(url: string): Observable<HttpResponse<Blob>> {
		return this.http
			.get<Blob>(url, {
				observe: 'response',
				responseType: 'blob' as 'json',
			})
			.pipe(
				catchError(err => {
					return throwError(err);
				}),
			);
	}

	getCombinedFile(url: string, body: any): Observable<Blob> {
		const requestOptions = {
			responseType: 'blob' as 'json',
		};
		return this.http.post<Blob>(url, body, requestOptions).pipe(
			catchError(err => {
				return throwError(err);
			}),
		);
	}

	putData(url: string, body: any): Observable<any> {
		return this.http.put<any>(url, body).pipe(
			catchError(err => {
				return throwError(err);
			}),
		);
	}

	patchData(url: string, body: any): Observable<any> {
		return this.http.patch<any>(url, body).pipe(
			catchError(err => {
				return throwError(err);
			}),
		);
	}

	deleteData(url: string): Observable<any> {
		return this.http.delete<any>(url).pipe(
			catchError(err => {
				return throwError(err);
			}),
		);
	}
}
