<div class="container-fluid">
	<div class="image-position">
		<div class="row image-bg m-3">
			<div class="col content-position">
				<img alt="error-image" src="assets/images/generic-error.svg" />
			</div>
		</div>
		<div class="row content-bg m-3">
			<div class="col content-position">
				<div class="error-message FreightSansProMedium text-center m-5">
					<div class="p-5"></div>
					<div class="pb-3 error-text FreightSansProMedium">Server Connectivity Error</div>
					<div class="error-sub-text FreightSansProMedium">
						We are currently experiencing a server connectivity issue. Please try refreshing the page;<br>otherwise, please contact your Western Alliance Bank representative. 
					</div>
				</div>
			</div>
		</div>
	</div>
</div>