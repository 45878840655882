import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { AUTHORIZATION_SESSION_KEY, CoreConstants } from '@core/core.constants';
import { HomeService } from '@layouts/home/services/home.service';
import { ToasterService } from '@shared/services/toaster.service';
import { Observable } from 'rxjs';

@Injectable()
export class LoggedInGaurd implements CanActivate {
    constructor(public router: Router, private authService: AuthService, private homeService: HomeService, private toasterService: ToasterService) {}

    canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const authenticated = sessionStorage.getItem(AUTHORIZATION_SESSION_KEY);
        const isLogout: string = sessionStorage.getItem('isLogout') as string;

        if (CoreConstants.externalURLs.includes(window.location.origin)) {
            if (authenticated == null) {
                return true;
            } else {
                if (authenticated && !JSON.parse(isLogout)) {
                    this.homeService.logout().subscribe({
                        next: async () => {
                            this.authService.isAuthenticationCompleted$.next(false);
                            AuthService.clearSessionStorage();
                            this.router.navigateByUrl(CoreConstants.externalLoginPath);
                            sessionStorage.setItem('isLogout', JSON.stringify(true));
                            this.toasterService.toastSuccess('User Logout Successfully', 3000, 'toast-right');
                        },
                        error: (err: HttpErrorResponse) => {
                            this.authService.isAuthenticationCompleted$.next(false);
                            AuthService.clearSessionStorage();
                            this.router.navigateByUrl(CoreConstants.externalLoginPath);
                            this.toasterService.toastFailure(err.message, 3000, 'toast-right');
                        },
                    });
                    return true;
                }
            }
            return false;
        }
        return false;
    }
}
