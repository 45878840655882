export const StatusCodes = [
	{
		code: '400',
		phrase: 'Bad Request',
	},
	{
		code: '401',
		phrase: 'Unauthorized User',
	},
	{
		code: '403',
		phrase: 'Forbidden',
	},
	{
		code: '404',
		phrase: 'Not Found',
	},
	{
		code: '405',
		phrase: 'Method Not Allowed',
	},
	{
		code: '408',
		phrase: 'Request Timeout',
	},
	{
		code: '413',
		phrase: 'Payload Too Large',
	},
	{
		code: '500',
		phrase: 'Internal Server Error',
	},
	{
		code: '502',
		phrase: 'Bad Gateway',
	},
	{
		code: '503',
		phrase: 'Service Unavailable',
	},
	{
		code: '504',
		phrase: 'Gateway Time-out',
	},
];
