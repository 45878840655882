import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestService } from '@core/services/http-request/http-request.service';
import { ApiBaseUrl } from '@shared/services/apiBaseUrl.service';
import { CoreConstantsApi } from '@core/core.constants';
import { PasswordCheck, PasswordCheckResponse, UpdatePasswordRequest, CreatePasswordRequest, ValidateCreateUser, UpdatePwdInfo } from '../models/update-password.model';

@Injectable({
    providedIn: 'root'
})
export class UpdatePasswordService {
    constructor(public httpService: HttpRequestService, private apiService: ApiBaseUrl) {}

    verifyPreviousUserPassword(passwordDetails: PasswordCheck): Observable<PasswordCheckResponse> {
        const apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.previousPasswordCheck);
        return this.httpService.postData(apiurl, passwordDetails);
    }

    //update password details
    updateUserPassword(api: string, reqBody: UpdatePasswordRequest): Observable<UpdatePwdInfo> {
        let apiurl = this.apiService.getOrganizationApiUrl(api, null, true);
        return this.httpService.patchData(apiurl, reqBody);
    }

    //get getOTPVerify details
	getOTPVerify(api: string, reqBody: any): Observable<any> {
		let apiurl = this.apiService.getOrganizationApiUrl(api, null, true);
		return this.httpService.postData(apiurl, reqBody);
	}

    onAfterPasswordUpdate(): Observable<any> {
        let apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.logout);
        return this.httpService.postData(apiurl, null);
    }

	onCreateUserPassword(params: CreatePasswordRequest): Observable<UpdatePwdInfo> {
		const apiUrl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.createNewPassword);
		return this.httpService.postData(apiUrl, params);
	}

    getUserName(username: string): Observable<string> {
		const apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.getUserName, `userName=${encodeURIComponent(username)}`);
		return this.httpService.postData(apiurl, null);
	}

    getValidateUserToken(userToken: string): Observable<ValidateCreateUser> {
		const apiUrl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.validateCreateUserToken, `token=${encodeURIComponent(userToken)}`);
		return this.httpService.getData(apiUrl);
    }

    getValidateUserToken2(): Promise<any> {
		const apiUrl = this.apiService.getOrganizationApiUrl('api/User/Health');
		return this.httpService.getData(apiUrl).toPromise();
    }

    //validate login details
	loginCheck(api: string, reqBody: any): Observable<any> {
		let apiurl = this.apiService.getOrganizationApiUrl(api, null, true);
		return this.httpService.postData(apiurl, reqBody);
	}
}
