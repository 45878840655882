import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { CoreConstants, PasswordCriteria } from '@core/core.constants';
import { ValidateCreateUser } from '@modules/update-password/models/update-password.model';
import { UpdatePasswordService } from '@modules/update-password/services/update-password.service';
import { ToasterService } from '@shared/services/toaster.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreatePasswordGuard implements CanActivate {
  isAuthenticated: boolean = true;
  constructor(private passwordService: UpdatePasswordService, private authService: AuthService,
    private router: Router, private toasterService: ToasterService,) {

  }

  canActivate(activatedRoute: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>{
    this.passwordService.getValidateUserToken(activatedRoute.queryParams.token).subscribe({
      next: (data: ValidateCreateUser) => {
        this.isAuthenticated = data.status;
        if (this.isAuthenticated) {
            localStorage.setItem('validatedUser', JSON.stringify(data?.username));
            this.authService.setUserName(data.username);
            return true;
        } else {
            this.router.navigateByUrl(CoreConstants.externalLoginPath);
            this.toasterService.toastFailure(PasswordCriteria.ExpiredPasswordLink, 3000, 'toast-right');
            return false;
        }
      },
    });
    if(this.isAuthenticated) {
        return true ;
    }
    else {
      this.router.navigateByUrl(CoreConstants.externalLoginPath);
      this.toasterService.toastFailure(PasswordCriteria.ExpiredPasswordLink, 3000, 'toast-right');
        return false;
    }
  }
}