import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PERMISSIONS } from '../model/permissions.enum';
import { AuthService } from '../services/auth.service';

@Injectable()
export class PermissionsGaurd implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.authService.getDealAccessToken(route.paramMap.get('dealId') ?? '').pipe(
			map(() => {
				const permissionsRequired = (route.data?.permissions || []) as PERMISSIONS[];
				const usersPermissions = this.authService.permissionList;
				if (usersPermissions.some(permission => permissionsRequired.includes(permission as PERMISSIONS))) {
					return true;
				} else {
					this.router.navigate(['dashboard']);
				}
				return false;
			}),
		);
	}
}
