import { Component, OnInit } from '@angular/core';
import { Footer, Header } from '@core/core.constants';

@Component({
	selector: 'app-user-authenticate-background',
	templateUrl: './user-authenticate-background.component.html',
	styleUrls: ['./user-authenticate-background.component.scss'],
})
export class UserAuthenticateBackgroundComponent implements OnInit {
	WABTitle = Footer.WABTitle;
    WABLogo = Header.WABLogo;

	constructor() {}

	ngOnInit(): void {}
}
