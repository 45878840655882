import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { AUTHORIZATION_SESSION_KEY } from '@core/core.constants';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
	constructor(public router: Router, private authService: AuthService) {}

	canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		const authenticated = sessionStorage.getItem(AUTHORIZATION_SESSION_KEY);
		const authState = this.authService.isAuthenticationCompleted$.value;
		localStorage.setItem('authState', JSON.stringify(authState));
		if (authenticated && authState) {
			return true;
		} else {
			this.router.navigate(['login']);
			return false;
		}
	}

	canActivateChild(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		return this.canActivate();
	}
}
