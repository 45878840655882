export const CoreConstants = {
  dashboard: 'Dashboard',
  vesting: 'Vesting',
  solicitationSummary: 'Solicitation Summary with Executed Documents',
  paymentAccountActivity: 'Payment Account Activity',
  accountsOverview: 'Accounts Overview',
  userDetails: 'userDetails',
  secureKey: 'WABBES2022-02-10&',
  oneEm: '1em',
  countdown: 'countdown',
  codeExpired: 'The code expired',
  dashboardPath: '/dashboard',
  oktaAccessDenied:'User is not assigned to the client application. Please close the browser tab and try again with valid credentials.',
  printImages: 'printImages',
  alerts: 'Alerts',
  reports: 'Reports',
  dealDocumentsLibrary: 'Deal Documents Library',
  archiveDeals: 'Archive_Deals',
  backspace: 'Backspace',
  overview: 'Overview',
  accountOverview: 'Account_Overview',
  pdfOverviewName: 'AccountsDrilldown',
  viewPDF: 'viewPDF',
  amountPaid: 'Amount Paid',
  amountUnPaid: 'Amount Unpaid',
  defaultOverviewCardType: 'Escrow-border',
  overviewColumns: ['Date', 'Amount','Type', 'Code Name','Additional Info'],
  frontView: 'frontView',
  backView: 'backView',
  overviewFilter: ['All Debits', 'All Credits', 'Check', 'Deposit'],
  fileTypeDropdown: ['PDF'],
  activeDeals: 'Active_Deals',
  imageType: 'image/png',
  archiveColumns: ['Deal Party', 'Company/Target', 'Close Date'],
  externalLoginPath: '/login',
  internalLoginPath: '/login/internal',
  amountPaidKey: 'amountpaid',
  unpaidAmountKey: 'unpaidamount',
  paymentsOverview: [
    { name: 'All Debits', type: 'All Types' },
    { name: 'All Credits', type: 'All Types' },
    { name: 'Check', type: 'All Types' },
    { name: 'Deposit', type: 'All Types' },
    { name: 'Withdrawal', type: 'All Types' },
    { name: 'Transfer', type: 'All Types' },
    { name: 'Other payments', type: 'All Types' },
  ],
  timeFrames: [
    { name: 'All available dates ( 24 months )' },
    { name: 'Month' },
    { name: 'Custom date range' },
  ],
  currentTansaction: 'Current transactions',
  multipleSelected: 'Multiple Selected',
  microsoftExcel: 'Microsoft Excel Format',
  transactionArray: [
    'Date',
    'Amount', 
    //'Description',        
    //'Balance',
    //'Posting Date',
    'Type',
    'Code Name', 
    'Additional Info',      
  ],
  permissionGroupArray: [
    'user management',
    'vesting schedule management'
  ],
  recentTransaction: 'Recent Transactions',
  recentTransactionUnderscore: 'Recent-Transactions',
  accountTransactions: 'Account_Transactions',
  dollarMin: '$ Min',
  min: 'Min',
  solicitationReport: 'Solicitation Report',
  fullSolicitationReport: 'Full_Solicitation_Report',
  userManagementReport: 'User Management Report',
  externalURLs: ['https://gateway-client-qa.westernalliancebank.com', 'https://gateway-client.westernalliancebank.com'],
  dollarMax: '$ Max',
  paymentEligibility: 'payment eligibility track',
  timeOptions24Hours: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
  payingAgent: 'Paying Agent',
  disbursingAgent: 'Disbursing Agent - List',
  disbursingAgentSolicitation: 'Disbursing Agent - Solicitation',
  escrowAgent: 'Escrow Agent',
  passwordPattern: '^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&])|(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])|(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*?&]))[A-Za-z0-9@$!%*?&]{14,}$',
  emailPattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\"[\w'].+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  lockUser: 'This users access has been successfully locked',
  unlockUser: 'This users access has been successfully unlocked',
  unlockUserMessage: 'User Status Updated to Unlocked',
  lockUserMessage: 'User Status Updated to Locked',
  emailSentMessage: 'Email successfully sent',
  activeDealtimeFrames: [
    { name: 'All available dates' },
    { name: 'Month' },
    { name: 'Custom date range' },
  ],
  certificates: 'Certificates',
  statusPending: 'Pending',
  statusMandate: 'Mandate',
  statusClosed: 'Closed',
  statusArchived: 'Archived',
  paidUnpaidReportLabel: 'Paid/Unpaid Report',
  fullSolicitationReportLabel: 'Full Solicitation Report',
  currentMonthlyStatementLabel: 'Current Monthly Statements',
  paidUnpaidReport: 'Paid_Unpaid_Report',
  currentMonthlyStatement: 'Current_Monthly_Statement',
  noDocuments: 'There are no documents available.',
  TwilioMFATimeout: 120,
  auditLog: 'Data Maintenance Queue_Audit Log',
  gatewayclient:'A One Time Access Code is required to access the Gateway Client.'
};
export const CoreConstantsApi = {
  activeDeals: 'api/deal/deal/activedeals',
  archiveDeals: 'api/deal/deal/archiveDeals',
  dealDetails: 'api/deal/deal/dealdetails',
  contacts: 'api/deal/deal/dealcontacts',
  paymentAccountActivity: 'api/deal/deal/paymentaccountactivity',
  userDetails: 'api/user/authenticate',
  sendOtp: 'api/user/sendotp',
  otpVerify: 'api/user/verify',
  alerts: 'api/deal/deal/alerts',
  reports: 'api/deal/document/reports',
  dealDocuments: 'api/deal/document/dealdocuments',
  dealDocumentsDrilldown: 'api/deal/document/dealDocumentsDrilldown',
  downloadDealDocument: 'api/deal/document/downloaddealdocument',
  downloadAllDealDocuments: 'api/deal/document/downloadalldealdocuments',
  downloadDealDocuments: 'api/deal/document/downloaddealdocuments',
  solicitationsSummary: 'api/deal/deal/solicitationssummary',
  solicitationsSummaryDocumentsTypes: 'api/deal/deal/solicitationsdocumenttypes',
  solicitationsSummaryBulkDownload: 'api/deal/document/bulkdownloaddocuments',
  solicitationsSummaryBulkDownloadBlobs: 'api/deal/document/bulkdownloaddocumentsv2',
  downloadBlob:'api/deal/document/blob',
  downloadBlobV2:'api/deal/document/downloadblob',
  solicitationsSummaryDocumentsOverview:'api/deal/deal/solicitationssummarydocumentsoverview',
  accountsOverview: 'api/deal/deal/accountsoverview',
  overviewDrilldown: 'api/deal/deal/accountoverviewdrilldown',
  overviewDrilldownTransactions:
    'api/deal/deal/accountoverviewdrilldowntransactions',
  accountsOverviewStatement: 'api/deal/deal/accountoverviewstatement',
  vesting: 'api/deal/deal/vesting',
  closeDateFilter: 'api/deal/deal/filterclosedate',
  downloadPaidUnpaidReport: 'api/deal/document/downloadpaidunpaidreport',
  downloadSolicitationReport: 'api/deal/document/downloadsolicitationreport',
  downloadActiveDeals: 'api/deal/deal/downloadactivedeals',
  paymentAccountDrilldown: 'api/deal/deal/paymentaccountactivitydrilldown',
  permissionDealsList: 'api/user/PermissionGroup/getdealnamesbyclient',
  getDealIds: 'api/user/PermissionGroup/getpermissiongroupdealids',
  allPermissionGroup: 'api/user/PermissionGroup/getallpermissiongroups',
  allUsers: 'api/User/getusercounts',
  downloadUsers: 'api/User/downloaduserreport',
  particularUserData: 'api/User/getusers',
  permissionCapability: 'api/user/PermissionGroup/getcapabilities',
  addPermissionGroup: 'api/user/PermissionGroup',
  updatePassword: 'api/User/resetpassword',
  getUserName: 'api/User/useremailbyusername',
  userActiveDeals: 'api/User/userdeals',
  userActiveDealDetails: 'api/User/userdetails',
  getUserDetails: 'api/User/usercompletedetails',
  revokeUser: 'api/User/revokeuser',
  updateUserDetails: 'api/user/updateuser',
  getUserRoles: 'api/user/Role/getroletypes',
  getBlobUrl: 'api/deal/document/blobTest',
  createUser: 'api/user/createuser',
  getUserDeals: 'api/User/GetDealDetailsForDealId',
  getUserPermissionGroups: 'api/user/PermissionGroup/getpermissiongroupsbyclient',
  getUserPermissions: 'api/user/PermissionGroup/getpermissiongroupdetails',
  getAccountsForDeal: 'api/deal/Deal/accountsfordeal',
  downloadFullSolicitationReport: 'api/deal/Document/downloadfullsolicitationreport',
  downloadBlobDocument: 'api/deal/Document/',
  getSolicitationTrack: 'api/deal/Deal/solicitationtracksprogress',
  getSolicitationGrid: 'api/deal/Deal/solicitationtrackgrid',
  solicitationCard: 'api/deal/Deal/solicitationtrackcard',
  getSolicitationGridAndProgress: 'api/deal/Deal/solicitationtrackgridwithprogresstracker',
  addMaintenanceWindow: 'api/user/applicationmaintenance/addmaintenancewindow',
  updateMaintenanceWindow: 'api/user/applicationmaintenance/updatemaintenancewindow',
  nextMaintenanceWindow: 'api/user/applicationmaintenance/nextmaintenancewindow',
  checkMaintenanceWindow: 'api/user/ApplicationMaintenance/checkforongoingmaintenancewindow',
  bulkCompletedDocuments: 'api/deal/Document/bulkdownloaddocumentsv3',
  terminateMaintenanceMode: 'api/user/ApplicationMaintenance/disablemaintenancewindow',
  logout: 'api/user/logout',
  previousPasswordCheck: 'api/User/ValidatePasswords',
  updateUserPassword: 'api/User/UpdatePassword',
  lockUnlockUser: 'api/User/lockunlockuser',
  createNewPassword: 'api/User/createpassword',
  validateCreateUserToken: 'api/User/validatecreatepasswordtoken',
  triggerEmail: 'api/User/triggeremail',
  defaultPermissionGroup: 'api/user/PermissionGroup/getalldefaultpermissiongroups',
  permissionGroupV2: 'api/user/PermissionGroup/getallpermissiongroupsv2',
  lawyerDealsByStatus: 'api/deal/deal/dealstatusbyclient',
  lawyerActiveDeals: 'api/deal/deal/lawyeractivedeals',
  escrowBalanceByLawyer: 'api/deal/deal/escrowbalancebyclient',
  downloadCertificates: 'api/deal/Document/downloadcertificates',
  downloadMultidealPaidUnpaidReport: 'api/deal/Document/multidealscreenpaidunpaidreport',
  downloadMultidealFullSolicitationReport: 'api/deal/Document/multidealscreenfullsolicitationreport',
  downloadMultidealCurrentMonthlyStatement: 'api/deal/Deal/downloadcurrentmonthlystatement',
  escrowBuyerSeller: 'api/deal/deal/escrowbalancebydeals',
  downloadMultidealReportStatementSizes: 'api/deal/Document/multidealscreenreportsizes',
  lawyerStatmentsReports: 'api/deal/Deal/lawyerclientAnddeals',
  downloadLawyerStatementReports: 'api/deal/Document/generatenewreport',
  buyerSellerDealsByStatus: 'api/deal/Deal/dealstatusforbuyerseller',
  escrowBalanceByClientSide: 'api/deal/deal/escrowbalancebybuyer',
  clientDealsByStatus: 'api/deal/Deal/dealstatusbybuyer',
  buyerSellerDeals: 'api/deal/Deal/buyerandsellerdeals',
  clientactivedeals: 'api/deal/deal/clientactivedeals',
  clientUserDeals: 'api/deal/Deal/dealsforclientuser',
  dataMaintenanceQueue: 'api/user/DataMaintenanceQueue/datamaintenancequeue',
  dataMaintenanceQueueCounts: 'api/user/DataMaintenanceQueue/datamaintenancequeueoverview',
  dataMaintenanceQueueReport: 'api/user/DataMaintenanceQueue/datamaintenancequeuereport',
  auditLogs: 'api/user/DataMaintenanceQueue/datamaintenancequeueauditlogs',
  downloadAuditLogs: 'api/user/DataMaintenanceQueue/downloadauditlogs',
  bulkApproveReject: 'api/user/DataMaintenanceQueue/bulkapprovereject',
  pendingCount: 'api/user/DataMaintenanceQueue/datamaintenancequeuependingcount',
  reSubmitRejectedQueue: 'api/user/DataMaintenanceQueue/resubmitusertopendingstate',
  addNote: 'api/user/DataMaintenanceQueue/addnotestopendingapprovalrequest',
  updateNote: 'api/user/DataMaintenanceQueue/editnotes',
  deleteRequest: 'api/user/DataMaintenanceQueue/deleteuser',
  batchJobHealthCheck: 'api/user/BatchJobHealth/batchjobhealth',
  k2SyncLogs: 'api/user/AzureApiManagement/getK2SyncLogs',
  previewUser: 'api/User/previewUserVerify',
  auditUserHistoryLogs: 'api/user/DataMaintenanceQueue/UserUpdateAuditHistory',
};

export const CoreConstantsMockApi = {
  dealDetails: 'dealDetails',
  activeDeals: 'activeDeals',
  userDetails: 'userDetails',
  archiveDeals: 'archiveDeals',
};

export const CoreConstantsTitle = {
  externalUserTitle: 'Gateway Counsel',
  clientUserTitle: 'Gateway Client',
  InternalUserTitle: 'Gateway Client Portal',
};

export const AUTHORIZATION_SESSION_KEY = 'token';

export const paymentAcountData = [
  {
    dealId: 1,
    fullName: 'Cleark Kent',
    releaseEventStatus: 'Inprogress',
    allocated: 11111,
    unPaid: 11111,
    paid: 1111,
    email: 'customerService@wab.com',
    collapse: false,
    paymentGroups: [
      {
        releaseEvent: 'Closing',
        pymtStatus: 'paid',
        amtAllocated: 1111,
        amtUnpaid: 1111,
        amtPaid: 1111,
        imad: 'ALG57',
        pymtMethod: 'BCH',
        pymtDate: '08/25/2022',
      },
      {
        releaseEvent: 'Opening',
        pymtStatus: 'pending',
        amtAllocated: 2222,
        amtUnpaid: 2222,
        amtPaid: 222222,
        imad: 'CLG57',
        pymtMethod: 'ACH',
        pymtDate: '10/25/2022',
      },
    ],
  },
  {
    dealId: 2,
    fullName: 'Aleark Kent',
    releaseEventStatus: 'progress',
    allocated: 2222,
    unPaid: 22222,
    paid: 22,
    email: 'western@wab.com',
    collapse: false,
    paymentGroups: [
      {
        releaseEvent: 'opening',
        pymtStatus: 'not paid',
        amtAllocated: 5555,
        amtUnpaid: 55555,
        amtPaid: 555,
        imad: 'CLG57',
        pymtMethod: 'ACH',
        pymtDate: '08/25/2022',
      },
    ],
  },
  {
    dealId: 3,
    fullName: 'Cleark Kent3',
    releaseEventStatus: 'Inprogress',
    allocated: 11111,
    unPaid: 11111,
    paid: 1111,
    email: 'customerService@wab.com',
    collapse: false,
    paymentGroups: [],
  },
  {
    dealId: 4,
    fullName: 'Cleark Kent4',
    releaseEventStatus: 'Inprogress',
    allocated: 11111,
    unPaid: 11111,
    paid: 1111,
    email: 'customerService@wab.com',
    collapse: false,
    paymentGroups: [],
  },
  {
    dealId: 5,
    fullName: 'Cleark Kent5',
    releaseEventStatus: 'Inprogress',
    allocated: 11111,
    unPaid: 11111,
    paid: 1111,
    email: 'customerService@wab.com',
    collapse: false,
    paymentGroups: [],
  },
];

export const solicitationData = [
  {
    summary: [
      {
        name: 'Stockholder Consent',
        complete: 0,
        total: 200,
      },
      {
        name: '280G Consent',
        complete: 0,
        total: 180,
      },
      {
        name: 'Eligible for Payment',
        complete: 0,
        total: 160,
      }
    ],
    documents: [
      {
        documentType: 'Spousal Consent',
        totalDocuments: 100,
        notCompleted: 60,
        completed: 40,
      },
      {
        documentType: 'Document 8',
        totalDocuments: 90,
        notCompleted: 40,
        completed: 50,
      },
      {
        documentType: '280 G',
        totalDocuments: 110,
        notCompleted: 70,
        completed: 40,
      },
      {
        documentType: 'NotePayoff',
        totalDocuments: 80,
        notCompleted: 30,
        completed: 50,
      },
      {
        documentType: 'Option Termination Doc',
        totalDocuments: 100,
        notCompleted: 60,
        completed: 40,
      },
      {
        documentType: 'Document 8',
        totalDocuments: 90,
        notCompleted: 40,
        completed: 50,
      },
      {
        documentType: 'Document 10',
        totalDocuments: 140,
        notCompleted: 90,
        completed: 50,
      },
      {
        documentType: 'Joinder',
        totalDocuments: 100,
        notCompleted: 60,
        completed: 40,
      },
      {
        documentType: 'Document 11',
        totalDocuments: 100,
        notCompleted: 60,
        completed: 40,
      },
      {
        documentType: 'Certificates',
        totalDocuments: 160,
        notCompleted: 60,
        completed: 40,
      },
      {
        documentType: 'Tax Doc.',
        totalDocuments: 200,
        notCompleted: 60,
        completed: 140,
      },
    ],
    shareholders: [
      {
        name: 'Stockholder Consent',
        tracker: {
          inProgress:50,
          presented:50
        },
        shareholders: [],
      },
      {
        name: '280G Consent',
        tracker: {
          inProgress:100,
          presented:0
        },
        shareholders: [],
      },
      {
        name: 'Eligible for Payment',
        tracker: {
          inProgress:100,
          presented:0
        },
        shareholders: [],
      },
      {
        name: 'Name of Solicitation Track',
        tracker: {
          inProgress:0,
          presented:100
        },
        shareholders: [],
      },
    ],
  },
];
export const TeamContactView = {
  SREP: 'SREP',
  RM: 'RM',
};

export const permissionGroupView = {
  childColumns: ['groupName', 'granted', 'denied'],
};

export const NewPermissionCapabilities = {
  general: 'general',
  dealDashboardTiles: 'deal dashboard tiles',
  accountsOverview: 'accounts overview',
  contacts: 'deal contacts',
  solicitationSummary: 'solicitation summary',
  paymentAccountActivities: 'payment account activity',
  documentLibrary: 'documents library',
  reports: 'reports'
};

export const PermissionGroupsTab = {
  all: 'all',
  mine: 'mine',
  hasUser: 'hasUser',
  noUser: 'noUser',
};

export const PermissionGroupTableHeaders = {
  name: 'groupName',
  description: 'groupDescription',
  userCount: 'userCount',
  deniedCount: 'totalPermissionDeniedCount',
  grantedCount: 'totalPermissionGrantedCount',
  select: 'select',
  actions: 'actions',
};

export const UserPermissionGroupTableHeaders = {
  groupName: 'groupName',
  description: 'description',
  userCount: 'userCount',
  select: 'select',
  actions: 'actions',
};

export const CapabilitiesTooltipInfo = [
  'The general capabilities represent the different tabs on the left side navigation bar.',
  'The Deal Dashboard is made up of several tiles that capture high level deal information. By granting access to these capabilities, the user will only receive access to the summary tiles on the Dashboard (Solicitation Summary is an exception; summary card and drill down screen access can be granted here). To grant access to the respective drill down screens, check off at least one capability in the corresponding tiles.',
  'By selecting at least one contact type on the Deal Contacts tile, the user will receive access to the Deal Contacts drilldown screen where they can view contact information such as email address and phone number for the contacts added to their accessible deals.',
  'By selecting at least one capability on the Accounts Overview tile, the user will receive access to the Accounts Overview drilldown screen with more detailed information like account balances and transactions.',
  'By selecting at least one capability on the Payment Account Activity tile, the user will receive access to the Payment Account Activity drilldown screen with more detailed information like allocated payments and payment status tracking.',
  'By selecting at least one document type on the Documents Library tile, the user will receive access to the Documents Library drilldown screen where they can view and download documents that have been added to their accessible deals.',
  'By selecting at least one report on the Reports tile, the user will be able to download the corresponding excel document for the deals they have access to.'
];

export const CreateUser = {
  bridgeBank: 'bridgebank.com',
  westernAlliance: 'westernalliancebank.com',
  internalURL: 'gateway-client-admin-qa',
  customGreetingTitle: 'Custom Greeting Message:',
}

export const userManagementTab = {
  all: 'all',
  bank: 'Bank Side Users',
  client: 'Client Side Users',
  buyer: 'Buyer Side Users',
  seller: 'Seller Side Users',
  external: 'External Counsel'
};

export const UserManagementTableHeaders = {
  Username: 'UserName',
  FirstName: 'FirstName',
  LastName: 'LastName',
  Email: 'Email',
  PhoneNumber: 'PhoneNumber',
  Company: 'Company',
  Deal: 'Deal',
  Roles: 'Roles',
  Permissions: 'Permissions',
  select: 'select'
};

export enum PaymentDrilldownComponentHeaders  {
  FullName = 'Full Name',
  Status = 'Status',
  ReleaseEvent = 'Release Event',
  PaymentStatus = 'Pymt. Status',
  AmountAllocated = 'Amt. Allocated',
  AmountUnpaid = 'Amt. Unpaid',
  AmountPaid = 'Amt. Paid',
  FeeAmount = 'Fee Amt.',
  FedRef = 'Fed Ref. / IMAD',
  SecurityType = 'Security Type',
  PaymentDate = 'Pymt. Date',
  PaymentMethod = 'Pymt. Method',
  Email = 'Email'
};

export const userDealView = {
  childColumns: ['DealName', 'ClientName', 'Roles', 'PermissionGroup', 'Description', 'OtherDetails'],
};

export const OKTA = {
  redirectPath: '/login/callback',
  LoginCallback: 'login/callback',
  oktaInternalUrls: ['https://gateway-client-admin-qa.westernalliancebancorp.com', 'https://gateway-client-admin.westernalliancebancorp.com', 'https://localhost:4200'],
  oktaPRODUrl: 'gateway-client-admin.westernalliancebancorp.com',
}

export const MenuLists = {
  dashboard: 'Dashboard',
  reports: 'Reports',
  schedule: 'Schedule',
  settings: 'Settings',
  userManagement: 'User Management',
  permissionGroups: 'Permission Groups',
  dataMaintenance: 'Data Maintenance Queue'
}

export class solicitationSummaryStates {
  public static readonly emptyStateCard: string ='There are no solicitation tracks associated with the account selected.';
  public static readonly viewAllMessage: string = 'Click View more details to see the status of all solicitation tracks.';
  public static readonly overviewTitle: string = 'Solicitation Documents';
  public static readonly OverviewTable = ['Document Type','No. of Total Documents', 'No. of Documents Not Completed', 'No. of Documents Completed', 'File Size'];
  public static readonly GenerateReports = {
    titleText: 'Preparing documents for download',
    subLabelText: 'Closing this dialogue box will not cancel your download. You may close this dialogue box and we will continue to prepare your documents. The download will start as soon as your documents are ready.',
};
}

export class RouteURLS {
  public static readonly maintenance: string ='/maintenance';
  public static readonly login: string = '/login';
  public static readonly updatePassword: string = 'user/update-password';
  public static readonly dashboard: '/dashboard';
  public static readonly createPassword: string = 'user/create-password';
}

export class ReportsDrilldown {
    public static readonly reportsDetails = [
        {
            id: 1,
            name: 'Paid/Unpaid Report',
            iconName: 'excel-blue.svg',
        },
        {
          id: 2,
          name: 'Full Solicitation Report',
          iconName: 'excel-blue.svg',
      },
    ];
}

export class FullSolicitationReportDrilldown {
  public static readonly reportsDetails = [
      {
          id: 1,
          name: 'Full Solicitation Report',
          iconName: 'excel-blue.svg',
      },
  ];
  public static readonly TaxDocumentTypes = ['W9 FORM', 'W-8BEN FORM', 'W-8BEN-E FORM', 'W-8CE FORM', 'W-8ECI FORM', 'W-8EXP FORM', 'W-8IMY FORM', 'W-9 FORM'];
  public static readonly activeIcon = 'assets/Icons/white-download.svg';
  public static readonly hoverIcon = 'assets/Icons/navy-download.svg';
  public static readonly excelImage = {
    activeIcon: 'assets/Icons/blue-excel-solicitation.svg',
    hoverIcon: 'assets/Icons/navy-excel-solicitation.svg'
  }
}

export class ngbPopoverDropdownHeights {
  public static readonly optionGroupHeight: number = 1.5;
  public static readonly optionHeight: number = 3.8;
  public static readonly downloadBtnHeight: number = 4.5
}

export class PaginationOptions {
  public static readonly startPage: number = 1;
  public static readonly maxSize: number = 5;
  public static readonly directionLinks: boolean = true;
  public static readonly responsive: boolean = true;
  public static readonly autoHide: boolean = false;
  public static readonly selectedEntry: number = 20;
  public static readonly pageSize: number = 20;
  public static readonly PaginationEntries: Array<number> = [5, 10, 20];
}

export class UserRoles {
  public static clientSuperAdmin: string = 'Bank Side Super Admin';
}

export class ErrorScreenMessages {
  public static readonly Statuses = [
    {
      code: 401,
      subText: 'Authorization required. Please contact your',
      linkText: 'Western Alliance Bank representative',
      finalText: 'if you require access to this page'
    },
    {
      code: 404,
      subText: 'The server cannot find the page you requested. Please contact your Western Alliance Bank representative if you require access to this page.',
      linkText: 'Previous Page',
      finalText: 'Go back to'
    },
    {
      code: 403,
      subText: 'Your permissions may not include access to this page. Please contact your ',
      linkText: 'Western Alliance Bank representative',
      finalText: 'if you require access to this page.'
    }
  ]
}

export const PayeeType = [
  {"id": 1, "optionName": "Shareholder"},
  {"id": 2, "optionName": "Transaction Expense Payee"}
];

export const SecurityType = [
  {"id": 1, "optionName": "Options"},
  {"id": 2, "optionName": "Warrants"},
  {"id": 3, "optionName": "Common Stock"},
  {"id": 4, "optionName": "Series Seed"}
];

export const PaymentMethod = [
  {"id": 1, "optionName": "Check"},
  {"id": 2, "optionName": "ACH"},
  {"id": 3, "optionName": "Wire"}
];

export class UserMessage {
  public static readonly RevokeMessage: string = 'Inactive User Account, please contact the administrator';
  public static readonly InvalidUserMessage: string[] = ['please use a valid external url for login', 'please enter a valid user name', 'please enter a valid password'];
  public static readonly PasswordUpdateMessage: string = 'Password updated successfully';
  public static readonly PasswordCreateMessage: string = 'Password created successfully';
  public static readonly LockedUserMessage: string = 'User Account has been locked due to multiple failed login attempts, please contact the administrator';
  public static readonly InvalidOTP: string = 'Please enter a valid otp';
  public static readonly UserNotFound: string = 'user not found';
  public static readonly ExpiredPasswordLink: string = 'create password link expired';
}

export class PasswordCriteria {
  public static readonly GuideLineHeader: string = 'In order to protect your password, please follow the password guidelines below,';
  public static readonly GuideLineSubHeader: string = 'Passwords must be <span class="OpenSanRegular">14</span> characters long AND must include all the following criteria:';
  public static readonly criteriaFirst: string = 'Password must include at least one Uppercase Alphabetic character (A through Z)';
  public static readonly criteriaSecond: string = 'Password must include at least one Lower case Alphabetic character (a through z)';
  public static readonly criteriaThird: string = 'Password must include at least one Numeric character <span class="OpenSanRegular">(0 through 9)</span>';
  public static readonly criteriaFourth: string = 'Password must include at least one of the following special characters ($, !, @, #, %, &, *, ?, ^, or _)';
  public static readonly criteriaLast: string = 'The previous six passwords cannot be re-used.';
  //public static readonly passwordRegex: RegExp = /(^(?!.*[-+~,()=<>:;"'{}|])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^_])(?!.*[-+~,()=<>:;"'{}|])|^(?!.*[-+~,()=<>:;"'{}|])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^_])(?!.*[-+~,()=<>:;"'{}|])|^(?!.*[-+~,()=<>:;"'{}|])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&#^_])(?!.*[-+~,()=<>:;"'{}|])|^(?!.*[-+~,()=<>:;"'{}|])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#^_])(?!.*[-+~,()=<>:;"'{}|]))/;
  public static readonly passwordRegex: RegExp =/(^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^_]))/;
  public static readonly criteriaMessage: string = 'Passwords may not be re-used; Select a new password.';
  public static readonly ExpiredPasswordLink: string = 'Create password link has expired';
}

export class PermissionGroups {
  public static readonly capabilitiesName: string[] = ['general', 'deal-dashboard','deal-contacts', 'accounts-overview', 'payment-account-activity', 'document-library', 'reports'];
  public static readonly CapabilityGroupName = {
    general: 'General',
    dashboardTiles: 'Deal Dashboard Tiles',
    accountOverview: 'Accounts Overview',
    contacts: 'Contacts' || 'Deal Contacts',
    paymentAccount: 'Payment Account Activity',
    documentLibrary: 'Documents Library',
    dealDashboard: 'Deal Dashboard',
    reports: 'Reports'
  };
  public static readonly CapabilityOrder: number[] = [0, 1, 6, 2, 3, 4, 5];
}

export class DealDocumentLibrary {
    public static readonly DocumentCategory = {
        AGM: 'Agreements',
        TMP: 'Templates',
        SOL: 'Solicitations',
        TND: 'Transaction Documents'
    };
    public static readonly NoRecordMessage: string = `<div fxlayout="row" class="child-container1 mt-5">
	<div fxlayout="column" fxLayoutAlign="center">
		<img fxFLex src="assets/images/PaymentAccount/payment.png" />
	</div>
	<div fxlayout="column" fxLayoutAlign="center">
		<span class="no-record fs-18px FreightSansPro">There are no documents available.</span>
	</div>
</div>`;
}

export class MultiDealsCLient {
    public static readonly PermissionLists = ['GE_Parent Deal Dashboard_Read', 'RP_Paid/Unpaid Report_Read', 'RP_Full Solicitation Report_Read', 'AO_Statements & Documents_Read'];
    public static readonly GenerateReports = {
        titleText: 'Generating reports for download',
        subLabelText: 'Requested reports are being generated. You will be notified once the reports are generated and ready for download. You can close this dialogue box now.',
    };
    public static readonly MultiClientHeader = 'Multi-Client Deals Dashboard';
    public static readonly MultiDealsHeader = 'Multi-Deal Dashboard';
    public static readonly EscrowByLawyer = 'Escrow Balances by Client';
    public static readonly EscrowByBuyerSeller = 'Escrow Balances by Deal';
    public static readonly LawyerGenerateNewReportDescription = 'Generate reports and statements based on clients, months, deals, etc.';
    public static readonly BuyerSellerGenerateNewReportDescription = 'Generate reports and statements based on months, deals, etc.';
    public static readonly LawyerReportTypes =[
      {
          name: 'Statements',
          value: 'CurrentStatement',
          id:1
      },
      {
        name: 'Account Balances',
        value: 'AccountBalanceReport',
        id:2
      }
    ];
    public static readonly ReportTypes =[
      {
          name: 'Monthly_Statements',
          value: 'CurrentStatement',
          id:1
      },
      {
        name: 'Account_Balances_Report',
        value: 'AccountBalanceReport',
        id:2
      }
    ];
    public static readonly EscrowByClientUsers = 'Escrow Balances by Buyer';
    public static readonly StatementReports = {
      titleText: 'Preparing reports for download',
      subLabelText: 'Closing this dialogue box will not cancel your download. You may close this dialogue box and we will continue to prepare your reports. The download will start as soon as your reports are ready.',
    };
    public static DownloadDocuments = 'Download Documents';
    public static DownloadReports = 'Download Reports';
}

export class DataMaintenanceQueue {
    public static readonly ApprovalQueue = {
        All: 'all',
        pendingApproval: 'pending approval',
        rejected: 'rejected'
    };
    public static readonly downloadTooltipText: string = 'All Approved Queue';
    public static readonly excelReport: string = 'Data Maintenance Queue_';
    public static readonly notesHeader = [
      {
          columnDef: 'description',
          header: 'Note Description',
      },
      {
          columnDef: 'savedBy',
          header: 'Note saved by',
      },
      {
          columnDef: 'submittedDate',
          header: 'Submitted date',
      },
    ];
    public static readonly auditLogsHeader = [
      {
        columnDef: '',
        header: '#',
    },
      {
          columnDef: 'userName',
          header: 'Username',
      },
      {
          columnDef: 'fullName',
          header: 'Full Name',
      },
      {
          columnDef: 'requestor',
          header: 'Requestor',
      },
      {
        columnDef: 'dateRequested',
        header: 'Date Requested',
    },
    {
        columnDef: 'approver',
        header: 'Action Taken by',
    },
    {
        columnDef: 'dateApproved',
        header: 'Date',
    },
    {
      columnDef: 'notes',
      header: 'Notes',
  },
  
    ];
    public static NotesDateFormat = 'MM/DD/YYYY';
    public static ApproveRejectRoles = ['Bank Side Super Admin', 'RM'];
    public static readonly rejectQueueMessage = 'Notes are required for rejected user requests';
    public static readonly rejectQueueSuccessMessage = 'User Rejected Successfully';
    public static readonly approveQueueMessage = 'User Approved Successfully';
    public static readonly requestDeleted = 'Request deleted; ';
    public static readonly requestApproved = 'Request approved; ';
    public static readonly dataMaintenanceQueue = 'DMQ';
    public static readonly userManagement = 'UM';
    public static readonly ApprovalStatusCodes = ['PD', 'PDC'];
}

export class Footer {
  public static PrivacyText = 'Privacy & Legal Notices';
  public static PrivacyURL = 'http://www.westernalliancebancorporation.com/privacy-legal-home';
  public static WABTitle = 'Western Alliance Bank, Member FDIC';
}

export class Header {
  public static WABLogo = 'assets/images/WAB-Logo.svg';
  public static HomeImage = {
    hoverIcon: 'assets/Icons/home-blue.svg',
    activeIcon: 'assets/Icons/home.svg'
  };
  public static ReportsImage = {
    hoverIcon: 'assets/Icons/reports-blue.svg',
    activeIcon: 'assets/Icons/reports.svg'
  };
  public static ScheduleImage = {
    hoverIcon: 'assets/Icons/schedule-blue.svg',
    activeIcon: 'assets/Icons/schedule.svg'
  };
  public static SettingsImage = {
    hoverIcon: 'assets/Icons/settings-blue.svg',
    activeIcon: 'assets/Icons/settings.svg'
  };
  public static UserImage = {
    hoverIcon: 'assets/Icons/user-blue.svg',
    activeIcon: 'assets/Icons/user.svg'
  }
}

export class WABEmail {
  public static readonly emailID = 'rmadmin@westernalliancebank.com'
}