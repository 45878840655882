<app-user-authenticate-background>
    <div passwordContent class="update">
        <div *ngIf="!sendOtpStatus; else otpAuth">
            <div class="card-title px-4 FreightSansProMedium">{{cardTitle}}</div>
            <app-password-criteria></app-password-criteria>
            <div class="new-password" *ngIf="isUpdatePasswordURL">
                <form [formGroup]="updatePassForm" (ngSubmit)="onSubmit()" class="update-page">
                    <div class="form-group">
                        <label for="currentPassword" class="currentPassword float-left">Enter
                            Current
                            Password</label>
                        <div class="input-group position-relative current-pass">
                            <input class="form-control current-pass rounded-0" id="current-PasswordId"
                                name="current-PasswordName" placeholder="Enter current password"
                                formControlName="currentPassword" autocomplete="current-password"
                                [ngClass]="getCurrentPasswordError ? 'border-error' : ''"
                                [type]="showCurrentPassword ? 'password' : 'text'" />
                            <div class="position-absolute eye-position input-group-append">
                                <span (click)="onCurrentPasswordToggle()" class="cursor-pointer">
                                    <img class="eye-icon"
                                        [src]="showCurrentPassword ? 'assets/Icons/eye-slash-icon.svg' : 'assets/Icons/eye-icon.svg'"
                                        alt="eye-icon" />
                                </span>
                            </div>
                        </div>
                        <div class="form-control-error float-left ml-2" *ngIf="
									(updatePassForm.controls['currentPassword'].invalid && updatePassForm.controls['currentPassword'].dirty) ||
									updatePassForm.controls['currentPassword'].touched
								">
                            <strong class="text-danger"
                                *ngIf="updatePassForm.controls['currentPassword'].errors?.required">
                                Current
                                Password is required. </strong>
                            <strong class="text-danger"
                                *ngIf="updatePassForm.controls['currentPassword'].errors?.minlength">
                                Password must be atleast <span class="OpenSansSemiBold">14</span>
                                characters.
                            </strong>
                            <div *ngIf="updatePassForm.controls['currentPassword'].hasError('hasCriteriaMatch')">
                                <strong class="text-danger">This
                                    password does not meet the criteria described in the password
                                    guidelines.</strong>
                            </div>
                        </div>
                    </div>
                    <div class="form-group pt-3">
                        <label for="newPassword" class="newPassword float-left">Enter New
                            Password</label>
                        <div class="input-group position-relative">    
                            <input class="form-control confirm-pass rounded-0" id="new-PasswordId"
                                name="new-PasswordName" placeholder="Enter new password" formControlName="newPassword"
                                autocomplete="new-password" [ngClass]="getPasswordError ? 'border-error' : ''" 
                                [type]="showNewPassword ? 'password' : 'text'"/>
                                <div class="position-absolute eye-position input-group-append">
                                    <span (click)="onNewPasswordToggle()" class="cursor-pointer">
                                        <img class="eye-icon"
                                            [src]="showNewPassword ? 'assets/Icons/eye-slash-icon.svg' : 'assets/Icons/eye-icon.svg'"
                                            alt="eye-icon" />
                                    </span>
                                </div>    
                        </div>
                        <div class="form-control-error float-left ml-2" *ngIf="
									(updatePassForm.controls['newPassword'].invalid && updatePassForm.controls['newPassword'].dirty) ||
									updatePassForm.controls['newPassword'].touched
								">
                            <strong class="text-danger" *ngIf="updatePassForm.controls['newPassword'].errors?.required">
                                New
                                Password is required. </strong>
                            <strong class="text-danger"
                                *ngIf="updatePassForm.controls['newPassword'].errors?.minlength">
                                Password must be atleast <span class="OpenSansSemiBold">14</span>
                                characters.
                            </strong>
                            <div *ngIf="updatePassForm.controls['newPassword'].hasError('hasCriteriaMatch')">
                                <strong class="text-danger">This
                                    password does not meet the criteria described in the password
                                    guidelines.</strong>
                            </div>
                        </div>
                    </div>
                    <div class="form-group pt-3">
                        <label for="confirmNewPassword" class="float-left confirmNewPassword">Confirm New
                            Password</label>
                        <div class="input-group position-relative">
                            <input class="form-control rounded-0 confirm-pass" id="confirm-NewPassword-one"
                                name="confirm-NewPassword-one" [type]="showPassword ? 'password' : 'text'"
                                placeholder="Confirm new password" formControlName="confirmNewPassword"
                                autocomplete="confirm-new-password"
                                [ngClass]="getConfirmPasswordError ? 'border-error' : ''" />
                            <div class="position-absolute eye-position input-group-append">
                                <span (click)="onPasswordToggle()" class="cursor-pointer">
                                    <img class="eye-icon"
                                        [src]="showPassword ? 'assets/Icons/eye-slash-icon.svg' : 'assets/Icons/eye-icon.svg'"
                                        alt="eye-icon" />
                                </span>
                            </div>
                        </div>
                        <div class="form-control-error float-left pt-1 ml-2" *ngIf="
									(updatePassForm.controls['confirmNewPassword'].invalid && updatePassForm.controls['confirmNewPassword'].dirty) ||
									updatePassForm.controls['confirmNewPassword'].touched
								">
                            <strong class="text-danger"
                                *ngIf="updatePassForm.controls['confirmNewPassword'].errors?.required">
                                Confirm new password is required. </strong>
                            <strong class="text-danger"
                                *ngIf="updatePassForm.controls['confirmNewPassword'].errors?.minlength || updatePassForm.controls['confirmNewPassword'].errors?.mustMatch">
                                The passwords you entered do not match.
                            </strong>
                        </div>
                    </div>
                    <div class="mt-4 text-center pt-1">
                        <button class="btn new-pass-btn" [disabled]="!updatePassForm.valid"
                            [ngClass]="!updatePassForm.valid ? 'opac0 not-allowed' : 'opac1'">Save &
                            Next</button>
                    </div>
                </form>
            </div>
            <div class="create-password" *ngIf="!isUpdatePasswordURL">
                <form [formGroup]="createNewPassForm" (ngSubmit)="onSubmit()" class="create-page">
                    <div class="form-group">
                        <label for="userName" class="userName float-left">Enter
                            Username</label>
                        <input class="form-control user-pass rounded-0 not-allowed" id="user-Id" name="user-Name"
                            placeholder="Enter username" formControlName="userName" autocomplete="user-password"
                            [ngClass]="getUserNameError ? 'border-error' : ''"  [readonly]="true"/>
                        <div class="form-control-error float-left ml-2" *ngIf="
									(createNewPassForm.controls['userName'].invalid && createNewPassForm.controls['userName'].dirty) ||
									createNewPassForm.controls['userName'].touched
								">
                            <strong class="text-danger" *ngIf="createNewPassForm.controls['userName'].errors?.required">
                                Username is required.</strong>
                            <strong class="text-danger" *ngIf="createNewPassForm.controls['userName'].errors?.pattern">
                                Please provide a
                                valid Username.</strong>
                        </div>
                    </div>
                    <div class="form-group pt-3">
                        <label for="newPassword" class="newPassword float-left">Enter New
                            Password</label>
                        <input type="password" class="form-control confirm-pass rounded-0" id="new-PasswordId"
                            name="new-PasswordName" placeholder="Enter new password" formControlName="newPassword"
                            autocomplete="new-password" [ngClass]="getNewPasswordError ? 'border-error' : ''" />
                        <div class="form-control-error float-left ml-2" *ngIf="
									(createNewPassForm.controls['newPassword'].invalid && createNewPassForm.controls['newPassword'].dirty) ||
									createNewPassForm.controls['newPassword'].touched
								">
                            <strong class="text-danger"
                                *ngIf="createNewPassForm.controls['newPassword'].errors?.required">
                                New
                                Password is required. </strong>
                            <strong class="text-danger"
                                *ngIf="createNewPassForm.controls['newPassword'].errors?.minlength">
                                Password must be atleast <span class="OpenSansSemiBold">14</span>
                                characters.
                            </strong>
                            <div *ngIf="createNewPassForm.controls['newPassword'].hasError('hasCriteriaMatch')">
                                <strong class="text-danger">This
                                    password does not meet the criteria described in the password
                                    guidelines.</strong>
                            </div>
                        </div>
                    </div>
                    <div class="form-group pt-3">
                        <label for="confirmNewPassword" class="float-left confirmNewPassword">Confirm New
                            Password</label>
                        <div class="input-group position-relative">
                            <input class="form-control rounded-0 confirm-pass" id="confirm-NewPassword-one"
                                name="confirm-NewPassword-one" [type]="showPassword ? 'password' : 'text'"
                                placeholder="Confirm new password" formControlName="confirmNewPassword"
                                autocomplete="confirm-new-password"
                                [ngClass]="getConfirmNewPasswordError ? 'border-error' : ''" />
                            <div class="position-absolute eye-position input-group-append">
                                <span (click)="onPasswordToggle()" class="cursor-pointer">
                                    <img class="eye-icon"
                                        [src]="showPassword ? 'assets/Icons/eye-slash-icon.svg' : 'assets/Icons/eye-icon.svg'"
                                        alt="eye-icon" />
                                </span>
                            </div>
                        </div>
                        <div class="form-control-error float-left pt-1 ml-2" *ngIf="
									(createNewPassForm.controls['confirmNewPassword'].invalid && createNewPassForm.controls['confirmNewPassword'].dirty) ||
									createNewPassForm.controls['confirmNewPassword'].touched
								">
                            <strong class="text-danger"
                                *ngIf="createNewPassForm.controls['confirmNewPassword'].errors?.required">
                                Confirm new password is required. </strong>
                            <strong class="text-danger"
                                *ngIf="createNewPassForm.controls['confirmNewPassword'].errors?.minlength || createNewPassForm.controls['confirmNewPassword'].errors?.mustMatch">
                                The passwords you entered do not match.
                            </strong>
                        </div>
                    </div>
                    <div class="mt-4 text-center pt-1">
                        <button class="btn new-pass-btn" [disabled]="!createNewPassForm.valid"
                            [ngClass]="!createNewPassForm.valid ? 'opac0 not-allowed' : 'opac1'">Save &
                            Next</button>
                    </div>
                </form>
            </div>
        </div>
        <ng-template #otpAuth>
            <div *ngIf="!showNewPasswordContent; else newPasswordContent">
                <div class="text-center">
                    <h2 class="auth-label">Two Step Authentication</h2>
                    <img class="image" src="assets/images/Verificationtwo-step.svg" />
                    <div class="gateway">{{ gatewayclient }}</div>
                    <div class="auth-label verify-text">Please choose a verification method</div>
                    <div class="d-flex flex-row justify-content-center">
                        <div class="p-3 cursor-pointer gateway-border" [class.add-border]="addBorder === 'email'">
                            <div class="update-email flex-column cursor-pointer"
                                (click)="verificationMethod('email', userDetails.userName)">
                                <div>
                                    <img alt="email-icon" class="email-icon" src="assets/Icons/mail-icon.svg" />
                                    <span class="navy-text RobotoMedium">Email Me</span>
                                </div>
                                <label class="email">{{ userDetails.userName | slice : 0 : 2
                                    }}******{{
                                    userDetails.userName | emailTruncate }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #newPasswordContent>
                <div class="text-center">
                    <h2 class="auth-label">Two Step Authentication</h2>
                    <img class="image" src="assets/images/Verification-OTP.svg" />
                    <div class="gateway-otp mx-5">
                        <span *ngIf="addBorder === 'email'; else otherMethod" class="FreightSansProRegular">Enter your
                            One
                            Time Access Code sent to
                            your email address
                        </span>
                        <ng-template #otherMethod>
                            Enter your One Time Access Code sent via
                            <span class="FreightSansProMedium">{{ addBorder | uppercase }}</span>
                            <span class="FreightSansProRegular">to the number ending in </span>
                            <span class="OpenSansSemiBold">{{ lastFourDigit | slice : -4 }}</span>
                        </ng-template>
                    </div>
							<app-otp-input (otpInputEmitter)="onOtpChange($event)" (keyUpEmitter)="enterKeyTwoStepSubmit()"></app-otp-input>
                    <button mat-raised-button class="update-otp-button"
                        [ngClass]="!otpEnter ? 'opac0' : 'opac1'" (click)="verifyOtp()" [disabled]="!otpEnter">
                        Update Password
                    </button>
                    <div class="resend-text">
                        <div id="countdown" class="OpenSansRegular"></div>
                        <button class="btn resend-code p-0 ml-1" (click)="sendOtp()" [disabled]="disabledOtp" [ngClass]="disabledOtp?'not-allowed':'cursor-pointer'">Resend
                            Code</button>
                    </div>
                </div>
            </ng-template>
        </ng-template>
    </div>
</app-user-authenticate-background>
<app-loader [id]="'create-password'"></app-loader>
