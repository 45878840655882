import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { PasswordCriteriaComponent } from '@shared/components/password-criteria/password-criteria.component';

@NgModule({
	declarations: [PasswordCriteriaComponent],
	imports: [CommonModule, FormsModule],
	providers: [],
	exports: [PasswordCriteriaComponent],
})
export class PasswordCriteriaModule {}
