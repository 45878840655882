import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';


@NgModule({
	imports: [
		MatTableModule,
		MatCardModule,
		MatDividerModule,
		MatFormFieldModule,
		MatInputModule,
		MatCheckboxModule,
		MatButtonModule,
		MatTooltipModule,
		MatSortModule,
		MatTreeModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatDialogModule,
		MatProgressBarModule,
		MatExpansionModule,
	],
	exports: [
		MatTableModule,
		MatCardModule,
		MatDividerModule,
		MatFormFieldModule,
		MatInputModule,
		MatCheckboxModule,
		MatButtonModule,
		MatTooltipModule,
		MatSortModule,
		MatTreeModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatDialogModule,
		MatProgressBarModule,
		MatExpansionModule,
	],
})
export class MaterialModule {}
