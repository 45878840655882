import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'currencyFormatPipe',
	pure: true,
})
export class CurrencyFormatPipe implements PipeTransform {
	transform(values: number): any {
		if (values !== null) {
			let value: any = Number(values);
			if (value >= 1000000 && value < 1000000000 ) {
				value = (value / 1000000).toFixed(2) + 'M';
			}
			if (value >= 1000000000 && value < 1000000000000 ) {
				value = (value / 1000000000).toFixed(2) + 'B';
			}
			if (value >= 1000000000000 ) {
				value = (value / 1000000000000).toFixed(2) + 'T';
			}
			if (value < 1000000 ) {
				value = Math.round(value);
			}
			return '$' + value.toLocaleString('en');
		}
	}
}
