import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpRequestService } from '@core/services/http-request/http-request.service';
import { ApiBaseUrl } from '@shared/services/apiBaseUrl.service';
import { AuthService } from '@auth/services/auth.service';
import { CoreConstantsApi } from '@core/core.constants';
import { InvalidUser } from '../models/login.model';

@Injectable({
	providedIn: 'root',
})
export class LoginService {
	userName = '';
	private revokeUserSubject = new BehaviorSubject<string>('');
	revokeMessage = this.revokeUserSubject.asObservable();
	private expiredPasswordLinkSubject = new BehaviorSubject<string>('');
	expiredPasswordLinkMessage = this.expiredPasswordLinkSubject.asObservable();
	private invalidUserSubject = new BehaviorSubject<{message: string, status: number}>({message: '', status: 400});
	invalidUserMessage = this.invalidUserSubject.asObservable();

	constructor(public httpService: HttpRequestService, private apiService: ApiBaseUrl, private authService: AuthService) {}

	isLoggedIn(): Observable<boolean> {
		return this.authService.isAuthenticationCompleted$; // {2}
	}

	//get login details
	loginCheck(api: string, reqBody: any): Observable<any> {
		let apiurl = this.apiService.getOrganizationApiUrl(api, null, true);
		return this.httpService.postData(apiurl, reqBody);
	}

	getUserName(username: string): Observable<string> {
		const apiurl = this.apiService.getOrganizationApiUrl(CoreConstantsApi.getUserName, `userName=${encodeURIComponent(username)}`);
		return this.httpService.postData(apiurl, null);
	}

	//update password details
	updatePassword(api: string, reqBody: any): Observable<any> {
		let apiurl = this.apiService.getOrganizationApiUrl(api, null, true);
		return this.httpService.patchData(apiurl, reqBody);
	}

	setRevokeMessage(message: string): void {
		if (message) {
			this.revokeUserSubject.next(message);
		}
	}

    getExpiredMessage(): Observable<string> {
        return this.expiredPasswordLinkMessage;
    }

	setExpiredMessage(message: string): void {
		if (message) {
			this.expiredPasswordLinkSubject.next(message);
		}
	}

    getRevokeMessage(): Observable<string> {
        return this.revokeMessage;
    }

	setInvalidMessage(invalidMsg: InvalidUser): void {
		const msg:InvalidUser = {message: invalidMsg.message, status: invalidMsg.status};
		if (msg) {
			this.invalidUserSubject.next(msg);
		}
	}

    getInvalidMessage(): Observable<InvalidUser> {
        return this.invalidUserMessage;
    }
}
