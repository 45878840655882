<div class="container-fluid">
    <div class="image-position container-height">
        <div class="row image-bg m-3">
            <div class="col content-position">
                <img alt="error-image" src="assets/images/generic-error.svg" />
            </div>
        </div>
        <div class="row content-bg m-3">
            <div class="col content-position">
                <div class="error-message FreightSansProMedium text-center m-5">
                    <div class="p-5"></div>
                    <div class="pb-3 error-text FreightSansProMedium">Western Alliance Bank Gateway Portal is under
                        maintenance</div>
                    <div class="flex-grow justify-content-center bd-highlight error-sub-text FreightSansProMedium">
                        <div class="flex-fill bd-highlight">Western Alliance Bank Gateway Portal is temporarily
                            unavailable while undergoing maintenance.</div>
                        <div class="flex-fill bd-highlight">We expect to back online shortly and appreciate your
                            patience.</div>
                    </div>
                    <div class="flex-grow justify-content-start bd-highlight my-3 estimate-time">
                        <div class="flex-fill bd-highlight font-weight-bold">Estimated Return Time: <span class="OpenSansSemiBold fs-18px font-weight-bolder">{{endTime}}</span></div>
                    </div>
                    <div class="flex-grow justify-content-start bd-highlight my-3 warn-message"
                        *ngIf="isUserSuperAdmin">
                        <div class="d-flex flex-row bd-highlight align-items-start warn-background">
                            <div class="p-2 bd-highlight text-right"><img alt="warn-icon"
                                    src="assets/Icons/warn-yellow-icon.svg" class="img-fluid icon-width" />
                            </div>
                            <div class="p-2 bd-highlight FreightSansProMedium text-left text-wrap"> <span>If the
                                    Maintenance mode is terminated before it ends, user
                                    will have instantaneous access to the Client Portal again, but the portal may
                                    experience functional issues.</span></div>
                        </div>
                    </div>
                    <div class="flex-grow justify-content-start bd-highlight mt-3 terminate-mode"
                        *ngIf="isUserSuperAdmin">
                        <button class="btn flex-fill bd-highlight font-weight-bold cursor-pointer terminate-bg" data-toggle="modal"
                            data-target="#terminateModeModal"><span class="terminate-mode">Terminate Maintenance Mode</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Cancel Add User Modal-->
<app-modal-dialog modalId="terminateModeModal" [cancelFooter]="true" [submitFooter]="true">
    <div head><span class="custom-modal-header fs-30px">Confirmation</span></div>
    <div body>
        <div>
            <div class="p-2 bd-highlight text-center">
                <img alt="Warning-icon" class="warningIconLarge" src="assets/Icons/warn-yellow-icon.svg" />
            </div>
            <div class="p-2 bd-highlight text-center">
                <span class="required-modal-text FreightSansProMedium lh-23px fs-20px">Are you sure you want to turn OFF maintenance mode?</span>
            </div>
        </div>
    </div>
    <div footer>Cancel</div>
    <div footer1 (click)="onTerminateMode()">Yes</div>
</app-modal-dialog>
<!-- Cancel Add User Modal-->