import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoggedInGaurd } from './auth/gaurds/loggedin.gaurd';
import { MaintenanceComponent } from '@layouts/maintenance/maintenance.component';
import { ServerConnectivityErrorComponent } from '@layouts/server-connectivity-error/server-connectivity-error.component';
import { MaintenanceGaurd } from '@layouts/maintenance/guards/maintenance.guard';
import { AuthGuard } from '@auth/gaurds/auth.gaurd';
import { RoleGaurd } from '@auth/gaurds/role.gaurd';
import { ROLES } from '@auth/model/roles.enum';
import { CreatePasswordGuard } from '@auth/gaurds/create-password.guard';
import { UpdatePasswordComponent } from '@modules/update-password/update-password.component';
import { PreviewUserComponent } from '@modules/Users/user-management/components/preview-user/preview-user.component';

const routes: Routes = [	
	{
		path: 'previewUser',
		component: PreviewUserComponent,
	},	
	{
		path: 'user/create-password',
		canActivate: [CreatePasswordGuard],
		component: UpdatePasswordComponent,
	},
	{
		path: 'login',
		canActivate: [LoggedInGaurd],
		loadChildren: () => import('@modules/login/login.module').then(mod => mod.LoginModule),
		pathMatch: 'full',
	},
	{
		path: 'forgot-password',
		canActivate: [LoggedInGaurd],
		loadChildren: () => import('@modules/forgot-password/forgot-password.module').then(mod => mod.ForgotPasswordModule),
	},
	{
		path: '',
		loadChildren: () => import('@layouts/home/home.module').then(mod => mod.HomeModule),
	},
	{
		path: 'maintenance',
		canActivate: [MaintenanceGaurd],
		component: MaintenanceComponent,
	},
	{
		path: 'server-connectivity-error',
		component: ServerConnectivityErrorComponent,
	},
	{
		path: 'user/update-password',
		canActivate: [AuthGuard, RoleGaurd],
		component:UpdatePasswordComponent,
		data: { roles: [ROLES.CLIENT_SIDE_SUPER_ADMIN, ROLES.CLIENT_SIDE_HR, ROLES.BUYER_SIDE_COUNSEL, ROLES.BUYER_SIDE_HR, ROLES.LAWYER, ROLES.SELLER_PARTY_1, ROLES.SELLER_PARTY_2] },
	},
	{ path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
