import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PERMISSIONS } from '../model/permissions.enum';
import { AuthService } from '../services/auth.service';

@Directive({
	selector: '[permissionRequired]',
})
export class PermissionRequiredDirective implements OnInit {
	@Input() permissionRequired: PERMISSIONS[] = [];

	constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private authService: AuthService) {}

	ngOnInit() {
		if (this.isUserAllowed()) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			return;
		}
		this.viewContainer.clear();
	}

	private isUserAllowed(): boolean {
		return this.authService.havePermission(this.permissionRequired);
	}
}
