import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

/** Shared modules */
import { MaterialModule } from '@shared/modules/material.module';
import { PipesModule } from '@shared/modules/pipes.module';
import { PasswordCriteriaModule } from '@shared/modules/shared/password-criteria.module';
/** Third party package */
import { NgOtpInputModule } from 'ng-otp-input';
/**Component */
import { UserAuthenticateBackgroundComponent } from '@shared/components/user-authenticate-background/user-authenticate-background.component';

@NgModule({
    declarations: [UserAuthenticateBackgroundComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, PipesModule, NgOtpInputModule, PasswordCriteriaModule],
    providers: [],
    exports: [UserAuthenticateBackgroundComponent],
})
export class UserAuthenticateBackgroundModule {}
