import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-otp-input',
	templateUrl: './otp-input.component.html',
	styleUrls: ['./otp-input.component.scss'],
})
export class OTPInputComponent implements OnInit {
	@Output() otpInputEmitter = new EventEmitter();
	@Output() keyUpEmitter = new EventEmitter();
	OTPInputValue: string = '';
	constructor() {}

	ngOnInit(): void {}

	onChangeOTPInput(event: string): void {
		const OTPValue: string = event;
		this.otpInputEmitter.emit(OTPValue);
	}

	enterKeyTwoStepSubmit(): void {
		if(this.OTPInputValue.length>6) {
			this.keyUpEmitter.emit(true);
		} else {
			this.keyUpEmitter.emit(false);
		}
	}
}
